import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import {
  WifiOutlined,
  HomeOutlined,
  UserOutlined,
  ToolOutlined,
  FileAddOutlined,
  LogoutOutlined,
  AppstoreTwoTone,
  DashboardOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Divider, Layout, Menu, theme } from 'antd';
const { Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}


const Nav = ({ defaultCollapsed, collapsed, setCollapsed }) => {

  const location = useLocation();

  const [user, setUser] = useState({})

  const token = localStorage.getItem('usertoken')

  // const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(80)

  const [selectedKey, setSelectedKey] = useState(null);

  const [trigger, setTrigger] = useState(null);

  const items = [
    // getItem(<Link to='/home'>Home</Link>, '1', <HomeOutlined />),
    getItem(<Link to='/dashboard'>Dashboard</Link>, '1', <DashboardOutlined />),
    // getItem(<Link to='/accounts'>Accounts</Link>, '2', <UserOutlined />),
    user.role==='Admin' && getItem(<Link to='/accounts'>Accounts</Link>, '2', <UserOutlined />),
    (user.role==='Admin' || user.role==='Monitoring Team') && getItem(<Link to='/repairs'>Repairs</Link>, '3', <ToolOutlined />),
    user.role==='Admin' && getItem(<Link to='/import'>Import</Link>, '4', <FileAddOutlined />),
    // getItem(<Link to='/contacts'>Contacts</Link>, '5', <SolutionOutlined />),
    (user.role==='Admin' || user.role==='Monitoring Team' || user.role==='Call Centar') && getItem(<Link to='/' onClick={() => {localStorage.removeItem('usertoken')}}>Logout</Link>, '6', <LogoutOutlined />),
  ];
  

  useEffect(() => {

    if(location.pathname === '/dashboard') {
      setSelectedKey('1')
    } else if(location.pathname === '/accounts'){
      setSelectedKey('2')
    } else if(location.pathname === '/repairs'){
      setSelectedKey('3')
    } else if(location.pathname === '/import'){
      setSelectedKey('4')
    }
    // else if(location.pathname === '/contacts'){
    //   setSelectedKey('5')
    // }

    const decoded = jwtDecode(token)

    setUser({
      name: decoded.name,
      surname: decoded.surname,
      role: decoded.role
    })

    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setCollapsed(true);
        setTrigger(undefined);
      } else {
        setCollapsed(true);
        setCollapsedWidth(80);
        setTrigger(null);
      }
    };

    handleResize(); // Initial call to set the state based on the current screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [location, token]);

  return (

      // <Sider trigger={trigger} className="sider" style={{position: 'fixed', left: 0, height: '100vh', zIndex: '100', 
      //   // backgroundImage: 'linear-gradient(135deg, #6253E1, #04BEFE)', 
      //   backgroundColor: '#405189'
      // }} collapsedWidth={collapsedWidth} collapsible defaultCollapsed={true} collapsed={collapsed} onCollapse={(value) => setCollapsed(!collapsed)} 
      <Sider trigger={trigger} className="sider" style={{position: 'fixed', left: 0, height: '100vh', zIndex: '100', 
        // backgroundImage: 'linear-gradient(135deg, #6253E1, #04BEFE)', 
        backgroundColor: '#405189'
      }} collapsedWidth={collapsedWidth} collapsible defaultCollapsed={true}  collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
      breakpoint='xs'
      onBreakpoint={(broken) => {
        if (broken) {
          setCollapsed(collapsed);
          setCollapsedWidth(0)
          // onCollapse={(value) => setCollapsed(value)}
          setTrigger(undefined);

        } else {
          setCollapsed(true);
          setCollapsedWidth(80)
          setTrigger(null);
        }
        
      }}
      >
        <div className="demo-logo-vertical">
          <WifiOutlined />
          {/* <Divider style={{margin: 0}}></Divider> */}
        </div>
        <Menu theme="dark" selectedKeys={[selectedKey]} mode="inline" items={items} style={{background: 'none'}}/>

        {/* {collapsed === false && <p style={{
          position: 'fixed',
          bottom: 30,
          left: 65  ,
          // right: 0,
          textAlign: "center",
          color: "#BCC0D1",
          fontSize: 12
        }}>REALBAU</p>} */}
      </Sider>
      
  );


}

export default Nav;