import { message } from 'antd';
import { useMemo } from 'react';


const useNotifications = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const notify = useMemo(() => ({
    success: (content) => {
      messageApi.open({
        type: 'success',
        content,
      });
    },
    error: (content) => {
      messageApi.open({
        type: 'error',
        content,
      });
    },
    warning: (content) => {
      messageApi.open({
        type: 'warning',
        content,
      });
    },
  }), [messageApi]);

  return { notify, contextHolder };
};

export default useNotifications;