import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {jwtDecode} from 'jwt-decode';
import "./css/Login.css";
import { GoPerson, GoLock } from "react-icons/go";
import { CiCircleRemove } from "react-icons/ci";
import { VscError } from "react-icons/vsc";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css'
import PulseLoader from "react-spinners/PulseLoader"
import { LockOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message, Alert } from 'antd';
// import wave from './img/wave_orange.png';
// import LoginImg from './img/log_opt.svg'
import LoginImg from './img/login_img_proba_4.svg'
// import LoginImg from './img/log.png'
// import wave from './img/wave_2.png';
// import wave from './img/wave_4_opt.png';
import wave from './img/wave_2.svg';
import logBcg from './img/log_bcg.svg'
// import bg from './img/bg.svg'
import bg from './img/bg_opt.svg'
// import avatar from './img/avatar.svg'
import avatar from './img/avatar_opt.svg'
// import dgf from './img/deutsche_gf.svg'
import dgf from './img/deutsche_gf_opt.png'
// import gfp from './img/gf_plus.png'
import gfp from './img/gf_plus_opt.png'
// import { NotificationsProvider, notifySuccess, notifyError, notifyWarning } from './helpers/Notifications';
// import { success, error, warning, contextHolder } from './helpers/notifications';

const Login = () => {

    document.title = 'RealBau Gmbh'

    // const BACKEND_URL = 'http://localhost:8000'
    const BACKEND_URL = 'https://realbaugmbh.com/api'

    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const[isCreated, setIsCreated] = useState('')

    const [expired, setExpired] = useState('')

    const [loading, setLoading] = useState(false)

    const [incorrectInfoDisplay, setIncorrectInfoDisplay] = useState('none')

    // const [api, contextHolder] = notification.useNotification();

    const [displayAlert, setDisplayAlert] = useState(false)


    useEffect(() => {

        if(localStorage.getItem('expired')!==null){
            
            setExpired('Session Expired')

            localStorage.removeItem('expired')
        } 
    
      }, [])



    const onInputChange1 = event => {
        setUsername(event.target.value);
    };

    const onInputChange2 = event => {
        setPassword(event.target.value);
    };

    function validateInput(input) {
        // Provjera da li input sadrži HTML ili JavaScript kod
        var containsHtmlOrScript = /<[^>]*>|<script\b[^>]*>/i.test(input);
        return !containsHtmlOrScript;
    }

    const onButtonClick = () => {

        setDisplayAlert(false)

        if(!validateInput(username) || !validateInput(password)){

            setDisplayAlert(true)

        } else if(!username || !password) {

            // toast.warn('Empty Input', {
            //     position: "top-center",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     transition: Bounce,
            // });
            
            // warning();

        } else {

            setLoading(true)

            axios
            .post(`${BACKEND_URL}/login`, {
            username: username,
            password: password,
          })
          .then((response) => {
            // console.log(response)
            // console.log(response.data)
            // console.log(typeof response.data)
            if(typeof response.data === 'undefined' || response.data.error)
            { 
                setLoading(false)
                navigate('/');
            //   setIncorrectInfoDisplay("flex")
                // toast.error('Incorrect username or password', {
                //     position: "top-center",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     transition: Bounce,
                // });

                setDisplayAlert(true)

                // api['error']({
                    // message: 'Incorrect username or password',
                    // description:
                    //   'Incorrect username or password',
                    //   style: {
                        // fontSize: 10,
                        // width: 600
                    //   },
                //   });
            //   setIsCreated('Incorrect Username or Password!')
            } else{
                // console.log(response.data)



                // const decoded = jwtDecode(response.data)
                // if(decoded && decoded.exp){

                    // const remainingTime = decoded.exp * 1000 - new Date().getTime()

                    // const existingTimeoutID = localStorage.getItem('timeoutID')
                    // if(existingTimeoutID){
                    //     clearTimeout(existingTimeoutID)
                    // }

                    // const timeoutID = setTimeout(() => {

                    //     localStorage.removeItem('usertoken')
                    //     localStorage.setItem('expired', 'Session Expired')
                    //     navigate('/')

                    //   }, remainingTime);

                    //   localStorage.setItem('timeoutId', timeoutID);

                    // localStorage.setItem("usertoken", response.data);
                    // navigate('/dashboard');

                // }

                if(response.data) {
                    localStorage.setItem("usertoken", response.data);
                    navigate('/dashboard');
                }

                // toast.error('Failed to login', {
                //     position: "top-center",
                //     autoClose: 3000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     transition: Bounce,
                // });

                setLoading(false)

                // localStorage.setItem("username", response.data.Username);
                // localStorage.setItem("name", response.data.Name);
                // localStorage.setItem("surname", response.data.Surname);
                // localStorage.setItem("role", response.data.Role)

                
            }
              
          })
          .catch((e) => {

            // toast.error('Failed to login', {
            //     position: "top-center",
            //     autoClose: 3000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     transition: Bounce,
            // });

            setLoading(false)

            console.log(e)

          }
          );

        }
      };

    //   const success = () => {
    //     messageApi.open({
    //       type: 'success',
    //       content: 'This is a success message',
    //     });
    //   };
    
    //   const error = () => {
    //     messageApi.open({
    //       type: 'error',
    //       content: 'This is an error message',
    //     });
    //   };
    
    //   const warning = () => {
    //     messageApi.open({
    //       type: 'warning',
    //       content: 'This is a warning message',
    //     });
    //   };

    return(
        
            <main className="login-page">

                {/* {contextHolder}r */}

                {/* <img className="wave img" src={wave}></img> */}

                {/* <img className="wave img log-bcg animate__animated animate__rubberBand" src={bg}></img> */}

                <div className="image-container"></div>
                <div className="image-cover"></div>

                {/* <img className="worker-img img" src={LoginImg}></img> */}

                {/* <ToastContainer /> */}

            
                <div className="gf-container">
                    <img className="gff img" src={dgf}></img>
                    <img className="gff img" src={gfp}></img>
                </div>
                
                

                <div className="login-box">

                    {/* <div className="gf-container">
                        <img className="gff img" src={dgf}></img>
                        <img className="gff img" src={gfp}></img>
                    </div>  */}

                    {/* <img className="avatar" src={avatar}></img> */}

                    {/* <h1>Login <LoginOutlined /></h1> */}
                    <h1>Login</h1>
                    {/* <p>RealBau - Fiber to the home Organization App</p> */}
                    <span className="realbau-app">RealBau Organization App</span>


                    {/* <form className="login-form"> */}

                        {/* <p className="expired">{expired}</p> */}
                    <div className="login-alert-container">
                        {displayAlert && <Alert className="login-alert" message="Incorrect username or password" type="error" showIcon closable />}
                    </div>
                        
                        <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: false,
                        }}
                        //   onFinish={onFinish}
                        // style={{width: '50%'}}
                        >
                        <Form.Item
                            name="username"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                            ]}
                        >
                            <Input size="large" value={username} onChange={(event) => setUsername(event.target.value)} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                            ]}
                        >
                            <Input.Password
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            size="large"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                        </Form.Item>

                        <Form.Item>
                            <Button style={{backgroundColor: '#0AB39C'}} loading={loading} iconPosition={'end'} size="large" type="primary" htmlType="submit" className="login-form-button" onClick={onButtonClick}>
                            Log in
                            </Button>
                            {/* Or <a href="">register now!</a> */}
                        </Form.Item>
                        </Form> 

                    {/* </form> */}
                    
                    <p className="powered">Powered by DHM Projekt</p>

                </div>
        

            </main>
    );

}

export default Login;