import React, { useEffect, useState, useRef } from "react";
import './css/Home.css'
import {jwtDecode} from 'jwt-decode';
import { AppstoreTwoTone, UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Select, Button, Avatar } from 'antd';
const { Header } = Layout;


const HeaderSection = (props) => {

  const [user, setUser] = useState({})

  const {
    token: { colorBgContainer, borderRadiusLG, colorText },
  } = theme.useToken();


  const token = localStorage.getItem('usertoken')


  useEffect(() => {

    const decoded = jwtDecode(token)

    setUser({
      name: decoded.name,
      surname: decoded.surname,
      role: decoded.role
    })

  }, [token]);
    

  return (

    <Header
    style={{
    padding: 0,
    // paddingRight: '50px',
    padding: '0 50px 0 20px',
    background: colorBgContainer,
    display: 'flex',
    justifyContent: 'space-between',
    // justifyContent: 'end',
    alignItems: 'center'
    // justifyContent: 'flex-end',
    }}>

{/* <Button
            type="text"
            icon={props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => props.setCollapsed(!props.collapsed)}
            style={{
              // fontSize: '16px',
              // fontSize: '350px',
              width: 64,
              height: 64,
            }}
          /> */}

      <Select
        defaultValue="EN"
        // onChange={handleChange}
        options={[
          { value: 'EN', label: 'EN'},
          { value: 'DE', label: 'DE', disabled: true },
          { value: 'ME', label: 'ME', disabled: true },
        ]}
      />

      <div className="logged-container">
        <div className="logged-user">
          {/* <span className="logged-name" style={{color: colorText}}>{props.user.name} {props.user.surname}</span> */}
          <span className="logged-name" style={{color: colorText}}>{user?.name} {user?.surname}</span>
          <span className="logged-role">{user?.role}</span>
        </div>
        {/* <AppstoreTwoTone className="logged-icon" /> */}
        <div><Avatar size="large" shape="square" style={{ backgroundColor: '#fde3cf', color: '#f56a00', marginLeft: 20, fontSize: 15 }}>{user.name && user.name[0]}{user.surname && user.surname[0]}</Avatar></div>
        {/* <UserOutlined className="logged-icon"/> */}
      </div>
      
    </Header>
      
  );


}

export default HeaderSection;