import React, { useEffect, useState, useRef } from "react";
import './css/Home.css'
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import "animate.css"
import "./css/Repairs.css"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav'
import HeaderSection from "./Header";
import useNotifications from "./helpers/useNotifications";
import {
  WifiOutlined,
  HomeOutlined,
  UserOutlined,
  ToolOutlined,
  FileAddOutlined,
  LogoutOutlined,
  AppstoreTwoTone,
  SearchOutlined,
  FilterFilled,
  UserAddOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
  LockOutlined,
  DeleteOutlined,
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
  DislikeOutlined,
  CheckSquareFilled,
  CalendarOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Menu, theme, FloatButton, Input, Switch, Button, Select, List, Pagination, Tag, Popconfirm, Modal, Form, Divider, Checkbox, Space, Card, Empty, Tabs } from 'antd';

const { Content, Footer } = Layout;
const { Option } = Select;
const { TextArea } = Input;


const Repairs = () => {

    document.title = 'Repairs | RealBau Gmbh'


    // const BACKEND_URL = 'http://localhost:8000'
    const BACKEND_URL = 'https://realbaugmbh.com/api'

    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(true);

    const [form] = Form.useForm();

    const {
        token: { colorBgContainer, borderRadiusLG, colorText },
      } = theme.useToken();

    const { notify, contextHolder } = useNotifications();

    const [logged, setLogged] = useState({
        name: '',
        surname: '',
        role: ''
    })

    const [currentPage, setCurrentPage] = useState(1)
    const [activeChecked, setActiveChecked] = useState(true)
    const [nonActiveChecked, setNonActiveChecked] = useState(true)

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('Admin')

    const [users, setUsers] = useState([]);

    const [reparatura, setReparatura] = useState([])

    const [doneChecked, setDoneChecked] = useState(false)
    const [nonDoneChecked, setNonDoneChecked] = useState(true)

    const [searchString, setSearchString] = useState('')
    const [gfChecked, setGfChecked] = useState(false);

    const [itemReparatura, setItemReparatura] = useState('[]')
    const [itemComments, setItemComments] = useState('[]')
    const [itemID, setItemID] = useState(null)

    const [modalRepairs, setModalRepairs] = useState(false)
    const [modalCom, setModalCom] = useState(false);
    const [indexToCom, setIndexToCom] = useState(null);
    const [commentRep, setCommentRep] = useState('')

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        const token = localStorage.getItem('usertoken')
    
        if(!token){
          navigate('/')
        } else{

            try {
                const decoded = jwtDecode(token)

                setLogged({
                    name: decoded.name,
                    surname: decoded.surname,
                    role: decoded.role
                })

            } catch(e) {
                console.error(e)
                navigate('/')
            }

            getReparatura()

        }
    
      }, [navigate, searchString, gfChecked, doneChecked, nonDoneChecked])

      const getReparatura = () => {
        // try {

            setLoading(true)

            let gf = 'gf_plus'

            if(gfChecked===true){
                gf = 'deutsche_gf'
            }

            axios
            .post(`${BACKEND_URL}/getReparatura`, {gf, searchString, doneChecked, nonDoneChecked}, {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {
                // console.log(response.data)
                setLoading(false)

                setReparatura(response.data)
                
            })
            .catch((e) =>{
                if(e.response.status===403 || e.response.status===401){
                    // localStorage.removeItem('usertoken');
                    navigate('/dashboard')
                }
            }
            );

          
        // } catch (error) {
        //   console.error('Error fetching users:', error);
        // }
      };

      const handleOnIdle = () => {
        localStorage.removeItem('usertoken');
        // localStorage.setItem('expired', 'Session Expired');
        localStorage.setItem('expired', 'Logged out');
        navigate('/');
      };

    //   const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    //     timeout: 1000 * 60 * 30, // 30 min
    //     // timeout: 1000 * 30,
    //     onIdle: handleOnIdle,
    //     debounce: 500
    //   });

        const toggleModalCom = (index) => {
            setModalCom(!modalCom);
            setIndexToCom(index)
            setCommentRep('')
        };


        const submitComment = (values) => {

                let gf = 'gf_plus'

                if(gfChecked===true){
                    gf = 'deutsche_gf'
                }

                const id = itemID


                const newComment = {
                    name: logged.name + ' ' + logged.surname,
                    date: new Date().toLocaleString('en-US', { timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric' }),
                    comment: values.Comment
                }
                

                // let updatedComment = ''

                // itemComments ? updatedComment = JSON.parse(itemComments)

                const updatedComment = JSON.parse(itemComments)
                // console.log("ovoo: ", updatedComment)
                updatedComment.push(newComment)

                const stringifyComment = JSON.stringify(updatedComment)


                axios
                .post(`${BACKEND_URL}/commentRep`, {gf, stringifyComment, id}, {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {
                    // console.log(response.data)

                    notify.success('Added Comment')

                    setModalCom(false)

                    getReparatura()
                })
                .catch((e) => {

                        if(e.response.status===403 || e.response.status===401){
                            localStorage.removeItem('usertoken');
                            navigate('/')
                        }

                        notify.error('Failed to add Comment')
                    }
                );
        }


    const changeRepDone = (id, repDone) => {

        // const updatedRowData = [...reparatura];
        // updatedRowData[indexToCom]['Reparatura Done'] = !updatedRowData[indexToCom]['Reparatura Done'];

        // if(updatedRowData[indexToCom]['Reparatura Done'] === 0){
        //     updatedRowData[indexToCom]['Reparatura Done'] = 1
        // } else {
        //     updatedRowData[indexToCom]['Reparatura Done'] = 0
        // }

        // setReparatura(updatedRowData);

        // console.log(reparatura)

        let gf = 'gf_plus'

        if(gfChecked===true){
            gf = 'deutsche_gf'
        }

        // const id = reparatura[indexToCom]['ID']

        // let repDone = reparatura[indexToCom]['Reparatura Done']


        axios
        .post(`${BACKEND_URL}/repDone`, {gf, repDone, id}, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
            'Content-Type': 'application/json',
            }})
        .then((response) => {
            // console.log(response.data)

            notify.success('Updated')

            // toggleModalCom()

            getReparatura()
        })
        .catch((e) =>{
            if(e.response.status===403 || e.response.status===401){
                localStorage.removeItem('usertoken');
                navigate('/')
            }
            notify.error('Update failed')

            // getReparatura()
        }
        );
    }

    const tabComItems = [{
        key: '1',
        label: 'Preview',
        children: <div style={{maxHeight: '45vh', overflowY: 'auto'}}>
        {itemComments && itemComments!=='[]' ? JSON.parse(itemComments).map((rep, index) => (
            <Card
            key={index}
            loading={false}
            size="small"
            // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
            title={<p>{rep.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{rep.date}</Tag></p>}
            style={{ marginBottom: 20 }}
            >
              <p>{rep.comment}</p>
            </Card>
          )) : <Empty description="No Comments"/>}
    </div>
      }, {
        key: '2',
        label: 'Add Comment',
        children: <Form
        layout='horizontal'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        form={form}
        name="form_add_comment"
        initialValues={{ modifier: 'public' }}
        clearOnDestroy
        onFinish={(values) => submitComment(values)}
      >
        <Form.Item name="Comment" label="Comment"  rules={[{ required: true }]} style={{marginTop: 20}}>
            <TextArea placeholder="Comment"/>
        </Form.Item>

        <Form.Item style={{margin: 'auto'}} wrapperCol={{ span: 22, offset: 15 }}>
            <Button htmlType="reset">reset</Button>
            <Button style={{backgroundColor: '#0AB39C', marginLeft: 10}} type="primary" htmlType="submit">Add</Button>
        </Form.Item>

    </Form>
      }]

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Nav defaultCollapsed={collapsed} collapsed={collapsed} setCollapsed={setCollapsed}/>

      {/* <Layout className="main-layout" style={{marginLeft: collapsed ? 80 : 200, transition: 'margin-left 0.2s'}}> */}
      <Layout className="main-layout" style={{marginLeft: 80}}>
        {/* <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed} logged={logged}/> */}
        <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed}/>

        <div className="page-info">
          <h3>Repairs {'>'}</h3>
        </div>

        {contextHolder}

        <FloatButton.BackTop />


        <Content
          style={{
            margin: '24px 16px',
          }}
        >

    <Modal
        open={modalRepairs}
        title="Repairs"
        onCancel={() => setModalRepairs(false)}
        destroyOnClose
        footer={null}
      >
        <Divider/>
        <div style={{maxHeight: '45vh', overflowY: 'auto'}}>
            {itemReparatura && itemReparatura!=='[]' ? JSON.parse(itemReparatura).map((rep, index) => (
                <Card
                key={index}
                loading={false}
                size="small"
                // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
                title={<p>{rep.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{rep.date}</Tag></p>}
                style={{ marginBottom: 20 }}
                >
                  <p>{rep.comment}</p>
                </Card>
              )) : <Empty description="No Comments"/>}
        </div>
        
      </Modal>

      <Modal
        
        open={modalCom}
        title="Comments"
        // okText="Create"
        // cancelText="Cancel"
        // okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
        onCancel={() => setModalCom(false)}
        destroyOnClose
        footer={null}
       
      >
        <Tabs
            defaultActiveKey="1"
            centered
            items={tabComItems}
        />
      </Modal>

        <div className="filter-container"
        style={{
            padding: 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
        }}
          >
            <div className="main-filters">
              {/* <Input allowClear className="search-input" placeholder="Search" prefix={<SearchOutlined />}  onChange={(event) => 
              {
                // handleChange(event)
              }} /> */}
              <Form

                // layout="vertical"
                form={form}
                name="form_search"
                // initialValues={{ modifier: 'public' }}
                clearOnDestroy
                // layout='horizontal'
                // labelCol={{ span: 10 }}
                // wrapperCol={{ span: 24 }}
                // onFinish={(values) => addNewVom(values)} 
                // onFinish={(values) => changePassword(values)}
                onValuesChange={(values) => setSearchString(values.Search)}
                style={{width: '40%'}}  
                >
                    <Form.Item name="Search">
                        <Input allowClear placeholder="Search" prefix={<SearchOutlined />}/>
                    </Form.Item>
                </Form>

                {/* <p>Found: {totalClients} Clients</p> */}
                {/* checkedChildren="Deutsche" unCheckedChildren="Plus" */}
              {/* <Switch onChange={(checked) => {
                setGfChecked(checked)
                setCurrentPage(1)
              }}/> */}
              <Select
                className="select-glasfaser"
                defaultValue="gf_plus"
                style={{
                  minWidth: '200px',
                }}
                onChange={(value) => {
                  if(value==='gf_plus') setGfChecked(false)
                  else setGfChecked(true)
                //   setCurrentPage(1)
                }}
                options={[
                  {
                    value: 'gf_plus',
                    label: 'Glasfaser Plus',
                  },
                  {
                    value: 'deutsche_gf',
                    label: 'Deutsche Glasfaser',
                  },
                ]}
              />
            </div>

            <div style={{fontSize: '12px', marginTop: '20px'}} className="main-filters">
              <div>
                <Checkbox style={{fontSize: '12px'}} onChange={() => {

                //   setCurrentPage(1)
                  setDoneChecked(!doneChecked)

                }}>Done</Checkbox>

                <Checkbox style={{fontSize: '12px'}} defaultChecked onChange={() => {

                //   setCurrentPage(1)
                  setNonDoneChecked(!nonDoneChecked)

                }}>Non-Done</Checkbox>
                
              </div>
              {/* {totalClients} Clients Found */}
            </div>
            
          </div>

          <List
            pagination={{ position: 'bottom', align: 'center' }}
            itemLayout="horizontal"
            bordered
            // loading={loading}
            style={{backgroundColor: 'white', borderColor: 'white', marginTop: 20}}
            // size="small"
            dataSource={reparatura}
            renderItem={(item, index) => (
              <List.Item 
            //   extra={<Button size="small" style={{marginLeft: 50, fontSize: 10, backgroundColor: '#0AB39C', color: 'white', border: 'none'}}>Comment</Button>} 
              actions={[<a key="list-loadmore-edit" onClick={() => {
                    setModalRepairs(true)
                    setItemReparatura(item.Reparatura)
                }}>{JSON.parse(item.Reparatura).length} <ToolOutlined /></a>, <a onClick={() => {
                    setModalCom(true)
                    item['Reparatura Komment'] ? setItemComments(item['Reparatura Komment']) : setItemComments('[]')
                    setItemID(item.ID)
                }}>{JSON.parse(item['Reparatura Komment']) ? JSON.parse(item['Reparatura Komment']).length : 0} <MessageOutlined /></a>, <Popconfirm  title="Are you sure?" okText={item['Reparatura Done'] ? 'Unmark' : 'Mark as Done'} onConfirm={() => changeRepDone(item.ID, !item['Reparatura Done'])}><a><CheckSquareFilled className={item['Reparatura Done'] ? 'doneRep' : 'nonDoneRep'}/></a></Popconfirm>]}>
                <List.Item.Meta
                  avatar={<Avatar style={{ }}><ToolOutlined /></Avatar>}
                  title={gfChecked ? item['Zip code'] : item.City}
                  description={`${item.Street} ${item['H.N.']!==null ? item['H.N.'] : ''} ${item['H.Z.']!==null ? item['H.Z.'] : ''}`}
                />
                <Tag color="blue">{item['Reparatura Done']===0 ? 'Ongoing' : 'Done'}</Tag>
              </List.Item>
            )}
          />

        </Content>

        
        <Footer
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
            fontSize: 13,
          }}
        >
          ©{new Date().getFullYear()} Powered by DHM Projekt
        </Footer>
      </Layout>
    </Layout>
  );


}

export default Repairs;