import React, { useEffect, useState, useRef } from "react";
import './css/Home.css'
import axios from 'axios';
import * as XLSX from 'xlsx';
import {jwtDecode} from 'jwt-decode';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from "react-router-dom";
import "animate.css"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav'
import HeaderSection from "./Header";
import useNotifications from "./helpers/useNotifications";
import {
  WifiOutlined,
  HomeOutlined,
  UserOutlined,
  ToolOutlined,
  FileAddOutlined,
  LogoutOutlined,
  AppstoreTwoTone,
  SearchOutlined,
  FilterFilled,
  UserAddOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
  LockOutlined,
  DeleteOutlined,
  InboxOutlined,
} from '@ant-design/icons';
// import type { UploadProps } from 'antd';
import { Avatar, Layout, Menu, theme, FloatButton, Input, Switch, Button, Select, List, Pagination, Tag, Popconfirm, Modal, Form, Divider, Checkbox, Space, Card, Empty, Tabs, Upload, message, InputNumber } from 'antd';
const { Dragger } = Upload;
const { Content, Footer } = Layout;
const { Option } = Select;


// const props: UploadProps = {
//     name: 'file',
//     multiple: false,
//     // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
//     onChange(info) {
//       const { status } = info.file;
//       if (status !== 'uploading') {
//         console.log(info.file, info.fileList);
//       }
//       if (status === 'done') {
//         message.success(`${info.file.name} file uploaded successfully.`);
//       } else if (status === 'error') {
//         message.error(`${info.file.name} file upload failed.`);
//       }
//     },
//     onDrop(e) {
//       console.log('Dropped files', e.dataTransfer.files);
//     },
//   };


const Import = () => {

    document.title = 'Import | RealBau Gmbh'

    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [data, setdata] = useState(null);

    const [collapsed, setCollapsed] = useState(true);

    const [form] = Form.useForm();

    const { notify, contextHolder } = useNotifications();

    const [gfChecked, setGfChecked] = useState(false);

    const {
        token: { colorBgContainer, borderRadiusLG, colorText },
      } = theme.useToken();

    // const BACKEND_URL = 'http://localhost:8000'
    const BACKEND_URL = 'https://realbaugmbh.com/api'

    const navigate = useNavigate();

    const [logged, setLogged] = useState({
        name: '',
        surname: '',
        role: ''
    })

    const [formBackgroundColor, setFormBackgroundColor] = useState('#ffffff');

    const [fileName, setFileName] = useState("No selected file");

    // const [data, setData] = useState(null)  

    const [postalCode, setPostalCode] = useState(0)

    const [showNotification, setShowNotification] = useState(false);
    const [notifyBackground, setNotifyBackground] = useState('#e8e8e8e2');
    const [notificationText, setNotificationText] = useState('')

    const [glasfaser, setGlasfaser] = useState('Glasfaser Plus')

    const [loading, setLoading] = useState(false)

    const fileRef = useRef(null)


    useEffect(() => {

        const token = localStorage.getItem('usertoken')
    
        if(!token){
          navigate('/')
        } else{

            try {
                const decoded = jwtDecode(token)

                setLogged({
                    name: decoded.name,
                    surname: decoded.surname,
                    role: decoded.role
                })

            } catch(e) {
                console.error(e)
                navigate('/')
            }


            axios
            .get(`${BACKEND_URL}/test`,  {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {
                
                
            })
            .catch((e) =>{
                if(e.response){
                    if(e.response.status===403 || e.response.status===401){
                        // localStorage.removeItem('usertoken');
                        navigate('/dashboard')
                    }
                } else {
                    console.log(e)
                    // localStorage.removeItem('usertoken');
                    // navigate('/')
                }
                    
                }
            );

            // if(file !== null){
            //     const reader = new FileReader();
            //     reader.onload = (e) => {
            //         const arrayBuffer = e.target.result;
            //         const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
    
            //         // Pretpostavljamo da je prvi sheet onaj koji nas zanima
            //         const sheetName = workbook.SheetNames[0];
            //         const sheet = workbook.Sheets[sheetName];
    
            //         // Konvertujemo sheet u JSON
            //         const data = XLSX.utils.sheet_to_json(sheet);
            //         setData(data);
            //     };
            //     reader.readAsArrayBuffer(file);
            // }

        }
    
      }, [navigate])

      const handleOnIdle = () => {
        localStorage.removeItem('usertoken');
        // localStorage.setItem('expired', 'Session Expired');
        localStorage.setItem('expired', 'Logged out');
        navigate('/');
      };

    //   const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    //     timeout: 1000 * 60 * 30, // 30 min
    //     // timeout: 1000 * 30,
    //     onIdle: handleOnIdle,
    //     debounce: 500
    //   });


    const handleUpload = (values) => {


            // let gf = 'gf_plus'

            // if(glasfaser === 'Deutsche Glasfaser') gf = 'deutsche_gf'

            const gf = values.Glasfaser


            if(gf === 'gf_plus') {
                data.forEach(client => {
                    client['Postal code'] = values['Postal Code']
                })
            }

            setLoading(true)


            axios.post(`${BACKEND_URL}/uploadExcel`, {data, gf}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                }})
                .then((response) => {
                    
                    // console.log(response.data)

                    setLoading(false)

                    notify.success('File Uploaded')
    
                })
                .catch((error) => {

                    setLoading(false)

                    console.error(error);

                    // console.error(error.response.data.Error.sqlMessage)

                    notify.error('File not uploaded')

                });

        // }
    }


    const handleFileChange = info => {
        // console.log(info);
        setFileList(info.fileList);
    
        if (info.fileList.length > 0) {
          const file = info.fileList[0].originFileObj;
          // console.log("Selected file:", file); // Dodano logovanje
    
          if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const reader = new FileReader();
            reader.onload = (e) => {
              const arrayBuffer = e.target.result;
              // console.log("Array buffer:", arrayBuffer); // Dodano logovanje
    
              const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
    
              const json = XLSX.utils.sheet_to_json(sheet);
              setdata(json);
            };
            reader.readAsArrayBuffer(file);
          } else {
            console.error("No file selected or file is not valid");
          }
        }
      };


  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Nav defaultCollapsed={collapsed} collapsed={collapsed} setCollapsed={setCollapsed}/>

      {/* <Layout className="main-layout" style={{marginLeft: collapsed ? 80 : 200, transition: 'margin-left 0.2s'}}> */}
      <Layout className="main-layout" style={{marginLeft: 80}}>
        {/* <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed} logged={logged}/> */}
        <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed}/>

        <div className="page-info">
          <h3>Import {'>'}</h3>
        </div>

        {contextHolder}

        <FloatButton.BackTop />


        <Content
          style={{
            margin: '24px 16px',
          }}
        >

        <div className="filter-container"
            style={{
                padding: 24,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                marginBottom: 20
            }}
        >

            <Form
                name="upload_form"
                form={form}
                // onFinish={(values) => console.log("values: ", values)}
                onFinish={(values) => handleUpload(values)}
                style={{ width: '60%', margin: 'auto' }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 13 }}
                initialValues={{'Glasfaser': 'gf_plus'}}
            >
                

                <Form.Item label="Upload" name="dragger">
                    <Upload.Dragger style={{backgroundColor: 'white'}} accept=".xlsx" name="files" maxCount={1} fileList={fileList} beforeUpload={() => false} onChange={(info) => handleFileChange(info)} onRemove={() => {
                        setFile(null)
                        setFileList(null)
                        setdata(null)
                    }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Support for a single upload.</p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item name="Glasfaser" label="Glasfaser">
                    <Select
                        size="large"
                        className="select-glasfaser"
                        // defaultValue="gf_plus"
                        style={{
                        minWidth: '200px',
                        }}
                        onChange={(value) => {
                        if(value==='gf_plus') setGfChecked(false)
                        else setGfChecked(true)
                        //   setCurrentPage(1)
                        }}
                        options={[
                        {
                            value: 'gf_plus',
                            label: 'Glasfaser Plus',
                        },
                        {
                            value: 'deutsche_gf',
                            label: 'Deutsche Glasfaser',
                        },
                        ]}
                    />
                </Form.Item>

                <Form.Item name="Postal Code" label="Postal Code" rules={[{ required: !gfChecked }]}>
                    <InputNumber disabled={gfChecked} size="large" placeholder="Postal Code" style={{width: '100%'}}></InputNumber>
                </Form.Item>

                {/* {data && (
                    <div>
                    <h3>JSON Preview:</h3>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                    </div>
                )} */}

                <Form.Item style={{margin: 'auto'}} wrapperCol={{ span: 13, offset: 14 }}>
                    <Button size="large" loading={loading} iconPosition={'end'} style={{width: '50%'}} type="primary" htmlType="submit" disabled={!data}>    
                        Upload
                    </Button>
                </Form.Item>

                

            </Form>

        </div>

            

        </Content>

        
        <Footer
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
            fontSize: 13,
          }}
        >
          ©{new Date().getFullYear()} Powered by DHM Projekt
        </Footer>
      </Layout>
    </Layout>
  );


}

export default Import;