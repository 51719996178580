import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
// import { useUser } from './UserContext';

const ProtectedRoute = ({ children, requiredRoles }) => {

    // const { user, setUser } = useUser();
//   const token = localStorage.getItem('usertoken');
  const [token, setToken] = useState(localStorage.getItem('usertoken'))
  const [user, setUser] = useState(null);

  useEffect(() => {
    // console.log(requiredRoles)
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser({
          name: decoded.name,
          surname: decoded.surname,
          role: decoded.role,
        });
      } catch (e) {
        console.error(e);
        localStorage.removeItem('usertoken');
        setToken(null);
      }
    }
  }, [token]);


    if (!token) {
      return <Navigate to="/" />;
    }


    if (user && !requiredRoles.includes(user?.role)) {
      return <Navigate to="/dashboard" />;
    }


  return children;
};

export default ProtectedRoute;