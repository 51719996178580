import React from 'react';
import {
    WifiOutlined,
    HomeOutlined,
    UserOutlined,
    ToolOutlined,
    FileAddOutlined,
    LogoutOutlined,
    AppstoreTwoTone,
    SearchOutlined,
    FilterFilled,
    DownloadOutlined,
    FilterOutlined,
    CopyOutlined,
    CommentOutlined,
    DeleteOutlined,
    ExpandAltOutlined,
    PhoneFilled,
    PhoneOutlined,
    QuestionCircleOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
  } from '@ant-design/icons';
  import { Layout, Menu, theme, FloatButton, Input, Switch, Button, Table, Space, Tag, Pagination, Select, Tabs, Divider, Checkbox, InputNumber, Card, message, Empty, Popconfirm, Radio, Tooltip, Badge, Form } from 'antd';
  const { Option } = Select;
  const { Content, Footer } = Layout;
  const { TextArea } = Input;
  const { TabPane } = Tabs;

const expandedRowRender = (record, index, gfChecked, voms, hbKomment, gbKomment, fmaKomment, newReparatura, commentNR, callResult, callReason, setHbKomment, setGbKomment, setFmaKomment, setNewReparatura, setCommentNR, setCallResult, setCallReason, handleDataChange, updateClientData, deleteCard, updateLoading, handleCallClient, removeSpecificCall, updateClientStatus, handleChangeText, role, textAreaRef, form) => {

    // setHbKomment('')
    // console.log(hbKomment)

    

    const tabItems = [
      {
        key: '1',
        label: (<span style={{display: 'flex'}}>Hausbegehung <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: record['HB Done']===1 ? 'block' : 'none'}}/></span>),
        // children: 'Content of Tab Pane 1',
        children: <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
          {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
          <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
      Hausbegehung
      </Divider>
            
            {/* <Space.Compact size="large" style={{width: '100%'}}> */}
            <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            
              <Space.Compact block>
                <Input size="large" value='KW' disabled style={{width: '20%'}}/>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                style={{width: '40%',}}
                className="datum-week"
                defaultValue=''
                value={record['HB Week'] || ''}
                onChange={(value) => handleDataChange(value, record.key, 'HB Week')}
                // onChange={()=>handleDataChange()}
                // value={rowData['HB Week'] || ""}
                placeholder=""
                >
                  <Option value="">Please select</Option>
                  {[...Array(53)].map((_, i) => (
                    <Option key={i + 1} value={i + 1}>
                      {i + 1}
                    </Option>
                  ))}
                </Select>
                  <Select
                  disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['HB Year'] || ''}
                  onChange={(value) => handleDataChange(value, record.key, 'HB Year')}
                  style={{
                    // minWidth: 250,
                    width: '40%',
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: '',
                      label: 'Please select',
                    },
                    {
                      value: '2021',
                      label: '2021',
                    },
                    {
                      value: '2022',
                      label: '2022',
                    },
                    {
                      value: '2023',
                      label: '2023',
                    },
                    {
                      value: '2024',
                      label: '2024',
                    },
                    {
                      value: '2025',
                      label: '2025',
                    },
                    {
                      value: '2026',
                      label: '2026',
                    },
                  ]}
                />
              </Space.Compact>
              
            </div>
              
            {/* </Space.Compact> */}
            

            {/* <Space.Compact size="large" style={{width: '100%'}}> */}
              <Select
                 disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['HB Vom'] || ''}
                  onChange={(value) => handleDataChange(value, record.key, 'HB Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />
            {/* </Space.Compact> */}

            {/* <Space.Compact size="large" style={{width: '100%'}}> */}
              {/* <TextArea rows={4} placeholder="Hausbegehung Komment" maxLength={50} style={{width: '100%'}}/> */}
              <Form

            // layout="horizontal"
            form={form}
           name={`form_hb_komment_${record.key}`}
            // name="form_hb_komment"
            initialValues={{ modifier: 'public' }}
            clearOnDestroy
            // onFinish={(values) => addUser(values)}
            onFinish={(values) => {
              form.setFieldsValue({
                hbKomment: '',
              });
            }}
            style={{width: '100%'}}
            // onFinish={(values) => onCreate(values)}  
            >
              <Form.Item name='hbKomment'>
                <TextArea disabled={role==='Admin' || role==='Monitoring Team' ? false : true} allowClear onChange={(event) => handleChangeText(event, 'HB Comment')} showCount rows={4} maxLength={100} placeholder="Hausbegehung Komment" style={{marginBottom: 30}}/>
              </Form.Item>

              <Form.Item>
                <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} loading={updateLoading} htmlType="submit" iconPosition={'end'} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
              </Form.Item>
            </Form>
              
            
            </div>
          {/* </Space> */}

          <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
      Comments
      </Divider>
            {/* <div> */}
              {/* <p style={{fontSize: 20,}}><CommentOutlined style={{fontSize: 40, color: '#1677FF', marginBottom: 30}}/></p> */}
            {/* </div> */}
            {/* <div> */}

              {record['HB Komment'] && record['HB Komment']!=='[]' ? JSON.parse(record['HB Komment']).map((comment, index) => (
                <Card
                key={index}
                loading={false}
                size="small"
                // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
                title={<p>{comment.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{comment.date}</Tag></p>}
                extra={role==='Admin' ? <Popconfirm  title="Are you sure?" okText="Delete" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => deleteCard(record.key, index, 'HB Komment')}><a><DeleteOutlined /></a></Popconfirm> : <></>}
                style={{ width: 300, marginBottom: 10 }}
                >
                  <p>{comment.comment}</p>
                </Card>
              )) : <Empty description="No Comments"/>}

              {/* <Card loading={false} size="small" title={<p>Emir Hadzajlic <span style={{fontSize: 10}}>12-03-2024</span></p>} extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
              <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
              <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
              <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card> */}
            {/* </div> */}
          </div>
          
        </div>
      },
      {
        key: '2',
        // label: 'Tiefbau',
        label: (<span style={{display: 'flex'}}>Tiefbau <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: record['Tiefbau Done']===1 ? 'block' : 'none'}}/></span>),
        children: <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
        {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        <div style={{width: '50%'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
          Tiefbau {gfChecked===false && 'HP+'}
        </Divider>
          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              defaultValue={""}
              value={record['Tiefbau Week'] || ''}
              onChange={(value) => handleDataChange(value, record.key, 'Tiefbau Week')}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                defaultValue=""
                value={record['Tiefbau Year'] || ''}
                onChange={(value) => handleDataChange(value, record.key, 'Tiefbau Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['Tiefbau Vom'] || ''}
                  onChange={(value) => handleDataChange(value, record.key, 'Tiefbau Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />
          {/* </Space.Compact> */}

          {/* {gfChecked===false && <Select
                size="large"
                defaultValue="lucy"
                style={{
                  // minWidth: 550,
                  width: '100%',
                  marginBottom: 20
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: 'jack',
                    label: 'HP+ JA',
                  },
                  {
                    value: 'lucy',
                    label: 'HP+ NEIN',
                  },
                ]}
              />} */}
              

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
            <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
          {/* </Space.Compact> */}
          </div>
        {/* </Space> */}
        
      </div>
      },
      {
        key: '3',
        // label: 'Gartenborung',
        label: (<span style={{display: 'flex'}}>Gartenborung <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: record['Gartenborung Done']===1 ? 'block' : 'none'}}/></span>),
        disabled: gfChecked ? true : false,
        children: gfChecked ? <div></div> : <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
          Gartenborung
        </Divider>
          
          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              // defaultValue={record['HB Week']}
              value={record['Gartenborung Week'] || ""}
              // onChange={(value) => handleDataChange({ target: { name: 'HB Week', value } }, index)}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'Gartenborung Week')}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                defaultValue=""
                value={record['Gartenborung Year'] || ""}
                onChange={(value) => handleDataChange(value, record.key, 'Gartenborung Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['Gartenborung Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'Gartenborung Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

              {/* <Select
                size="large"
                defaultValue="lucy"
                style={{
                  // minWidth: 550,
                  width: '100%',
                  marginBottom: 20
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: 'jack',
                    label: 'Gartenborung JA',
                  },
                  {
                    value: 'lucy',
                    label: 'Gartenborung NEIN',
                  },
                ]}
              /> */}

              <div style={{width: '100%'}}>
                <InputNumber 
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large" 
                  min={0} 
                  changeOnWheel
                  // controls='false'
                  addonBefore={"Metraza"}
                  value={record['Metraza'] || ""} 
                  onChange={(value) => handleDataChange(value, record.key, 'Metraza')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                />
              </div>
              
              <Form

              layout="horizontal"
              form={form}
          name={`form_gb_komment_${record.key}`}
              // name="form_gb_komment"
              initialValues={{ modifier: 'public' }}
              clearOnDestroy
              // onFinish={(values) => addUser(values)}
              onFinish={(values) => {
                form.setFieldsValue({
                  gbkomment: '',
                });
              }}
              style={{width: '100%'}}
              // onFinish={(values) => onCreate(values)}  
              >
                <Form.Item name='gbkomment'>
                  <TextArea disabled={role==='Admin' || role==='Monitoring Team' ? false : true} onChange={(event) => handleChangeText(event, 'Gartenborung Comment')} showCount rows={4} maxLength={100} placeholder="Gartenborung Komment" style={{marginBottom: 30}}/>
                </Form.Item>
                <Form.Item>
                  <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" htmlType="submit" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
                </Form.Item>
              </Form>
          </div>
        

        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
    Comments
    </Divider>
      {record['Gartenborung Komment'] && record['Gartenborung Komment']!=='[]' ? JSON.parse(record['Gartenborung Komment']).map((comment, index) => (
                <Card
                key={index}
                loading={false}
                size="small"
                // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
                title={<p>{comment.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{comment.date}</Tag></p>}
                extra={ role==='Admin' ? <Popconfirm  title="Are you sure?" okText="Delete" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => deleteCard(record.key, index, 'Gartenborung Komment')}><a><DeleteOutlined /></a></Popconfirm> : <></>}
                style={{ width: 300, marginBottom: 10 }}
                >
                  <p>{comment.comment}</p>
                </Card>
              )) : <Empty description="No Comments"/>}

        </div>
        
      </div>
      },
      {
        key: '4',
        // label: 'Faser',
        label: (<span style={{display: 'flex'}}>Faser <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: record['HK Faser Done']===1 && record['VzK Faser Done'] ? 'block' : 'none'}}/></span>),
        children: <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        <div style={{width: '50%'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
          HK Faser
        </Divider>
          
          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              // defaultValue={record['HB Week']}
              value={record['HK Faser Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'HK Faser Week')}
              // onChange={(value) => handleDataChange({ target: { name: 'HB Week', value } }, index)}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                defaultValue=""
                value={record['HK Faser Year'] || ""}
                onChange={(value) => handleDataChange(value, record.key, 'HK Faser Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['HK Faser Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'HK Faser Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

              <Divider style={{marginBottom: '50px'}} orientation="center" plain>
                VzK Faser
              </Divider>

              <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              // defaultValue={record['HB Week']}
              value={record['VzK Faser Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'VzK Faser Week')}
              // onChange={(value) => handleDataChange({ target: { name: 'HB Week', value } }, index)}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                value={record['VzK Faser Year'] || ""}
                defaultValue=''
                onChange={(value) => handleDataChange(value, record.key, 'VzK Faser Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['VzK Faser Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'VzK Faser Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

            <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
          </div>

        
        
      </div>
      },
      {
        key: '5',
        // label: 'Montage & Aktivirung',
        label: (<span style={{display: 'flex'}}>Montage & Aktievierung <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: record['Montage Done']===1 && record['Aktievirung Done']===1 ? 'block' : 'none'}}/></span>),
        children: <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
          Montage
        </Divider>
          
          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              value={record['Montage Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'Montage Week')}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                defaultValue=""
                value={record['Montage Year'] || ""}
                onChange={(value) => handleDataChange(value, record.key, 'Montage Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['Montage Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'Montage Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

              <Divider style={{marginBottom: '50px'}} orientation="center" plain>
                Aktievirung
              </Divider>

              <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              value={record['Aktievirung Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'Aktievirung Week')}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                defaultValue=""
                value={record['Aktievirung Year'] || ""}
                onChange={(value) => handleDataChange(value, record.key, 'Aktievirung Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['Aktievirung Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'Aktievirung Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

              <Form

              layout="horizontal"
              form={form}
          name={`form_fma_komment_${record.key}`}
              // name="form_fma_komment"
              initialValues={{ modifier: 'public' }}
              clearOnDestroy
              // onFinish={(values) => addUser(values)}
              onFinish={(values) => {
                form.setFieldsValue({
                  fmakomment: '',
                });
              }}
              style={{width: '100%'}}
              // onFinish={(values) => onCreate(values)}  
              >
                <Form.Item name='fmakomment'>
                  <TextArea disabled={role==='Admin' || role==='Monitoring Team' ? false : true} onChange={(event) => handleChangeText(event, 'FMA Comment')} showCount rows={4} maxLength={100} placeholder="Faser/Montage/Aktievirung Komment" style={{marginBottom: 30}}/>
                </Form.Item>

                <Form.Item>
                  <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" htmlType="submit" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
                </Form.Item>
              </Form>

          </div>

          <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
    Comments
    </Divider>
              {record['FMA Komment'] && record['FMA Komment']!=='[]' ? JSON.parse(record['FMA Komment']).map((comment, index) => (
                <Card
                key={index}
                loading={false}
                size="small"
                // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
                title={<p>{comment.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{comment.date}</Tag></p>}
                extra={role==='Admin' ? <Popconfirm  title="Are you sure?" okText="Delete" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => deleteCard(record.key, index, 'FMA Komment')}><a><DeleteOutlined /></a></Popconfirm> : <></>}
                style={{ width: 300, marginBottom: 10 }}
                >
                  <p>{comment.comment}</p>
                </Card>
              )) : <Empty description="No Comments"/>}

        </div>
        
      </div>
      },
      {
        key: '6',
        // label: 'Verrmessung',
        label: (<span style={{display: 'flex'}}>Vermessung <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: (record['Vermessung HA Done']===1 && record['Vermessung HP+ Done']===1) || record['Vermessung Done']===1 ? 'block' : 'none'}}/></span>),
        children: gfChecked ? <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        <div style={{width: '50%'}}>

              <Divider style={{marginBottom: '50px'}} orientation="center" plain>
                Vermessung
              </Divider>

              <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              value={record['Vermessung Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'Vermessung Week')}
              // onChange={(value) => handleDataChange({ target: { name: 'HB Week', value } }, index)}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                defaultValue=""
                value={record['Vermessung Year'] || ""}
                onChange={(value) => handleDataChange(value, record.key, 'Vermessung Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                // onChange={handleChange}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>

            {/* <TextArea showCount rows={4} maxLength={100} placeholder="Hausbegehung Komment" style={{marginBottom: 30}}/> */}

            <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
          </div>

          {/* <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Divider style={{marginBottom: '50px', color: 'grey'}} orientation="center" plain>
    Comments
    </Divider>
            <Card loading={false} size="small" title={<p>Emir Hadzajlic <span style={{fontSize: 10, color: 'grey'}}>12-03-2024</span></p>} extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
              <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
            </Card>
            <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
              <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
            </Card>
            <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
              <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
            </Card>
            <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
              <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
            </Card>
        </div> */}
        
      </div> : <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
          {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
          <div style={{width: '50%'}}>
  
                <Divider style={{marginBottom: '50px'}} orientation="center" plain>
                  Verrmessung HP+
                </Divider>
  
                <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
              <Space.Compact block>
                <Input size="large" value='KW' disabled style={{width: '20%'}}/>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                style={{width: '40%',}}
                className="datum-week"
                value={record['Vermessung HP+ Week'] || ""}
                defaultValue=''
                onChange={(value) => handleDataChange(value, record.key, 'Vermessung HP+ Week')}
                placeholder=""
                >
                  <Option value="">Please select</Option>
                  {[...Array(53)].map((_, i) => (
                    <Option key={i + 1} value={i + 1}>
                      {i + 1}
                    </Option>
                  ))}
                </Select>
                  <Select
                  disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['Vermessung HP+ Year'] || ""}
                  onChange={(value) => handleDataChange(value, record.key, 'Vermessung HP+ Year')}
                  style={{
                    // minWidth: 250,
                    width: '40%',
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: '',
                      label: 'Please select',
                    },
                    {
                      value: '2021',
                      label: '2021',
                    },
                    {
                      value: '2022',
                      label: '2022',
                    },
                    {
                      value: '2023',
                      label: '2023',
                    },
                    {
                      value: '2024',
                      label: '2024',
                    },
                    {
                      value: '2025',
                      label: '2025',
                    },
                    {
                      value: '2026',
                      label: '2026',
                    },
                  ]}
                />
              </Space.Compact>

            </div>

            <Divider style={{marginBottom: '50px'}} orientation="center" plain>
                  Verrmessung HA
                </Divider>
  
                <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
              <Space.Compact block>
                <Input size="large" value='KW' disabled style={{width: '20%'}}/>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                style={{width: '40%',}}
                className="datum-week"
                value={record['Vermessung HA Week'] || ""}
                defaultValue=''
                onChange={(value) => handleDataChange(value, record.key, 'Vermessung HA Week')}
                placeholder=""
                >
                  <Option value="">Please select</Option>
                  {[...Array(53)].map((_, i) => (
                    <Option key={i + 1} value={i + 1}>
                      {i + 1}
                    </Option>
                  ))}
                </Select>
                  <Select
                  disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['Vermessung HA Year'] || ""}
                  onChange={(value) => handleDataChange(value, record.key, 'Vermessung HA Year')}
                  style={{
                    // minWidth: 250,
                    width: '40%',
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: '',
                      label: 'Please select',
                    },
                    {
                      value: '2021',
                      label: '2021',
                    },
                    {
                      value: '2022',
                      label: '2022',
                    },
                    {
                      value: '2023',
                      label: '2023',
                    },
                    {
                      value: '2024',
                      label: '2024',
                    },
                    {
                      value: '2025',
                      label: '2025',
                    },
                    {
                      value: '2026',
                      label: '2026',
                    },
                  ]}
                />
              </Space.Compact>

            </div>
  
              {/* <TextArea showCount rows={4} maxLength={100} placeholder="Hausbegehung Komment" style={{marginBottom: 30}}/> */}
  
              <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
            </div>
  
            {/* <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Divider style={{marginBottom: '50px', color: 'grey'}} orientation="center" plain>
      Comments
      </Divider>
              <Card loading={false} size="small" title={<p>Emir Hadzajlic <span style={{fontSize: 10, color: 'grey'}}>12-03-2024</span></p>} extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
              <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
              <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
              <Card loading={false} size="small" title="Emir Hadzajlic" extra={<a href="#"><DeleteOutlined /></a>} style={{ width: 300, marginBottom: 10 }}>
                <p>Ovo je komentar koji je napisan za Hausbegehung ovog klijenta.</p>
              </Card>
          </div> */}
          
        </div>
      },
      {
        key: '7',
        // label: 'NvT/PoP',
        label: (<span style={{display: 'flex'}}>NvT/PoP <CheckCircleOutlined style={{color: '#3DF700', fontSize: 20, marginLeft: 10, display: record['NvT Spleißen Done']===1 && record['Pop Spleißen Done'] ? 'block' : 'none'}}/></span>),

        disabled: gfChecked ? true : false,
        children: gfChecked ? <div></div> : <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        {/* <Space direction="vertical" size="middle" style={{width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}> */}
        <div style={{width: '50%'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
          NvT Spleißen
        </Divider>
          
          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              value={record['NvT Spleißen Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'NvT Spleißen Week')}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                value={record['NvT Spleißen Year'] || ""}
                defaultValue=''
                onChange={(value) => handleDataChange(value, record.key, 'NvT Spleißen Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['NvT Spleißen Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'NvT Spleißen Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

              <Divider style={{marginBottom: '50px'}} orientation="center" plain>
                PoP Spleißen
              </Divider>

              <div className="date-container" style={{marginBottom: 20, width: '100%'}}>
            <Space.Compact block>
              <Input size="large" value='KW' disabled style={{width: '20%'}}/>
              <Select
              disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
              size="large"
              style={{width: '40%',}}
              className="datum-week"
              value={record['PoP Spleißen Week'] || ""}
              defaultValue=''
              onChange={(value) => handleDataChange(value, record.key, 'PoP Spleißen Week')}
              placeholder=""
              >
                <Option value="">Please select</Option>
                {[...Array(53)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
                <Select
                disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                size="large"
                value={record['PoP Spleißen Year'] || ""}
                defaultValue=''
                onChange={(value) => handleDataChange(value, record.key, 'PoP Spleißen Year')}
                style={{
                  // minWidth: 250,
                  width: '40%',
                }}
                options={[
                  {
                    value: '',
                    label: 'Please select',
                  },
                  {
                    value: '2021',
                    label: '2021',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2025',
                    label: '2025',
                  },
                  {
                    value: '2026',
                    label: '2026',
                  },
                ]}
              />
            </Space.Compact>
            
          </div>
            
          {/* </Space.Compact> */}
          

          {/* <Space.Compact size="large" style={{width: '100%'}}> */}
          <Select
          disabled={role==='Admin' || role==='Monitoring Team' ? false : true}
                  size="large"
                  defaultValue=""
                  value={record['PoP Spleißen Vom']}
                  onChange={(value) => handleDataChange(value, record.key, 'PoP Spleißen Vom')}
                  style={{
                    // minWidth: 550,
                    width: '100%',
                    marginBottom: 20
                  }}
                  options={voms.map(vom => ({
                    value: vom.Name,
                    label: vom.Name
                  }))}
                />

            <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
          </div>

        
        
      </div>,
      },
      {
        key: '8',
        label: 'Reparatura',
        children: <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
          <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
            <Divider style={{marginBottom: '50px'}} orientation="center" plain>
              Reparatura
            </Divider>
            
            <Form

              layout="horizontal"
              form={form}
          name={`form_reparatura_${record.key}`}
              // name="form_reparatura"
              initialValues={{ modifier: 'public' }}
              clearOnDestroy
              // onFinish={(values) => addUser(values)}
              onFinish={(values) => {
                form.setFieldsValue({
                  repkomment: '',
                });
              }}
              style={{width: '100%'}}
              // onFinish={(values) => onCreate(values)}  
              >
                <Form.Item name='repkomment'>
                  <TextArea disabled={role==='Admin' || role==='Monitoring Team' ? false : true} onChange={(event) => handleChangeText(event, 'Reparatura')} showCount rows={4} maxLength={100} placeholder="Reparatura" style={{marginBottom: 30}}/>
                </Form.Item>

                <Form.Item>
                  <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size="large" htmlType="submit" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
                </Form.Item>
              
              </Form>

          </div>

          <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
      Comments
      </Divider>
          {record['Reparatura'] && record['Reparatura']!=='[]' ? JSON.parse(record['Reparatura']).map((comment, index) => (
                <Card
                key={index}
                loading={false}
                size="small"
                // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
                title={<p>{comment.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{comment.date}</Tag></p>}
                extra={role==='Admin' ? <Popconfirm  title="Are you sure?" okText="Delete" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => deleteCard(record.key, index, 'Reparatura')}><a><DeleteOutlined /></a></Popconfirm> : <></>}
                style={{ width: 300, marginBottom: 10 }}
                >
                  <p>{comment.comment}</p>
                </Card>
              )) : <Empty description="No Comments"/>}

          </div>
        
        </div>,
      },
      {
        key: '9',
        label: 'Calling',
        children: <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
            Calling
          </Divider>

          <Radio.Group value={callResult} onChange={(event) => setCallResult(event.target.value)} buttonStyle="solid" style={{width: '100%', marginBottom: 20}} size='large'>
            <Tooltip title="Termine machen">
              <Radio.Button value="Termine machen" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>Termine machen</Radio.Button>
            </Tooltip>
            <Tooltip title="Nicht erreicht">
              <Radio.Button value="Nicht erreicht" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>Nicht erreicht</Radio.Button>
            </Tooltip>
            <Tooltip title="Specific Time On">
              <Radio.Button value="Specific Time" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>Specific Time On</Radio.Button>
            </Tooltip>
          </Radio.Group>

          <Radio.Group value={callReason} onChange={(event) => setCallReason(event.target.value)} buttonStyle="solid" style={{width: '100%', marginBottom: 20}} size='large'>
            <Tooltip title="Hausbegehung">
              <Radio.Button value="Hausbegehung" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>Hausbegehung</Radio.Button>
            </Tooltip>

            <Tooltip title="Montage">
              <Radio.Button value="Montage" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>Montage</Radio.Button>
            </Tooltip>

            <Tooltip title="Aktievierung">
              <Radio.Button value="Aktievierung" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>Aktievierung</Radio.Button>
            </Tooltip>

            <Tooltip title="All">
              <Radio.Button value="All" style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>All</Radio.Button>
            </Tooltip>
          </Radio.Group>

          <Form

              layout="horizontal"
              form={form}
          name={`form_calling_${record.key}`}
              // name="form_calling"
              initialValues={{ modifier: 'public' }}
              clearOnDestroy
              // onFinish={(values) => addUser(values)}
              onFinish={(values) => {
                form.setFieldsValue({
                  nrkomment: '',
                });
              }}
              style={{width: '100%'}}
              // onFinish={(values) => onCreate(values)}  
              >
                <Form.Item name='nrkomment'>
                  <TextArea onChange={(event) => handleChangeText(event, 'NR')} showCount rows={4} maxLength={100} placeholder="NR Comment" style={{marginBottom: 30}}/>
                </Form.Item>

                <Form.Item>
                  <div>
                    <Popconfirm  title="Are you sure?" okText="Off" icon={<QuestionCircleOutlined style={{ color: 'orange' }} />} placement="bottom" onConfirm={() => removeSpecificCall(record.key)}><Button disabled={record['NR Specific']===1 ? false : true} style={{color: '#D7B17A'}} type="text">Specific Time Off</Button></Popconfirm>
                    <Button size="large" htmlType="submit" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C', marginLeft: 10}} onClick={() => handleCallClient(record.key)}>Update</Button>
                  </div>
                </Form.Item>

              </Form>
        
        </div>

        <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Divider style={{marginBottom: '50px'}} orientation="center" plain>
    Comments
    </Divider>
        {record['NR'] && record['NR']!=='[]' ? JSON.parse(record['NR']).map((comment, index) => (
              // <Badge.Ribbon text={comment.callReason}>
                <Card
                key={index}
                loading={false}
                size="small"
                // title={<p>{comment.name} <span style={{fontSize: 10}}>{comment.date}</span></p>}
                // title={<><p>{comment.name}</p> <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{comment.date}</Tag></>}
                title={<p>{comment.name} <Tag style={{fontSize: 10, fontWeight: 100}} icon={<CalendarOutlined />}>{comment.date}</Tag></p>}
                extra={role==='Admin' ? <Popconfirm  title="Are you sure?" okText="Delete" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => deleteCard(record.key, index, 'NR')}><a><DeleteOutlined /></a></Popconfirm> : <></>}
                style={{ width: 300, marginBottom: 10 }}
                >
                  {/* <p style={{fontWeight: 'bold', color: 'grey'}}>{comment.callReason}</p> */}
                  {comment.callResult === 'Termine machen'}
                  <Tag color={comment.callResult==='Termine machen' ? 'green' : comment.callResult==='Nicht erreicht' ? 'red' : 'yellow'}>{comment.callResult}</Tag>
                  <Tag color='blue'>{comment.callReason}</Tag>
                  {/* <p>{comment.callResult}</p> */}
                  <Divider style={{marginTop: 5, marginBottom: 5}}/>
                  <p>{comment.comment}</p>
                </Card>
              // </Badge.Ribbon>
            )) : <Empty description="No Calling History"/>}

        </div>
      
      </div>,
      },
      {
        key: '10',
        label: 'Other',
        children: gfChecked ? <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        <div style={{width: '50%'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
            Others
          </Divider>

          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="DP"  value={record['DP'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="Kabel ID"  value={record['Kabel ID'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="Assignment number"  value={record['Assignment number'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="Project number"  value={record['Project number'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="GrundNA"  value={record['GrundNA'] || ""} style={{marginBottom: 20}}/>
          </div>

          <div style={{width: '100%'}}>
            <Popconfirm  title="Are you sure?" okText="Confirm" icon={<QuestionCircleOutlined style={{ color: 'orange' }} />} onConfirm={() => updateClientStatus(record.key)} placement="bottom" >{record.GrundNA==='R0' ? <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} type='primary' danger size="large" style={{width: '100%'}}>Remove</Button> : <Button type='primary' size="large" style={{width: '100%'}}>Acitave</Button>}</Popconfirm>
          </div>
        
        </div>
      
      </div> : <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        <div style={{width: '50%'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
            Others
          </Divider>

          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="NvT Area"  value={record['NVT Area'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="KLS-ID"  value={record['KLS-ID'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="Status"  value={record['Status'] || ""} style={{marginBottom: 20}}/>
          </div>
          <div style={{width: '100%'}}>
            <Input size="large" disabled addonBefore="Order ID"  value={record['OrderID'] || ""} style={{marginBottom: 20}}/>
          </div>

          <div style={{width: '100%'}}>
            <Popconfirm  title="Are you sure?" okText="Confirm" icon={<QuestionCircleOutlined style={{ color: 'orange' }} />} onConfirm={() => updateClientStatus(record.key)} placement="bottom" >{record.Kunde===1 ? <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} type='primary' danger size="large" style={{width: '100%'}}>Remove</Button> : <Button type='primary' size="large" style={{width: '100%'}}>Acitave</Button>}</Popconfirm>
          </div>
        
        </div>
      
      </div>,
      },
      {
        key: '11',
        label: 'Contacts',
        disabled: gfChecked ? true : false,
        children: gfChecked ? <></> : <div style={{display: 'flex', padding: 50, paddingTop: 0}}>
          
        <div style={{width: '50%'}}>
          <Divider style={{marginBottom: '50px'}} orientation="center" plain>
            Contacts
          </Divider>

          <div style={{width: '100%', marginBottom: 10}}>
            <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} style={{width: '100%'}} value={record['Tel. 1'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'Tel. 1')} size='large' placeholder='Tel. 1'></Input>
          </div>
          <div style={{width: '100%', marginBottom: 10}}>
            <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size='large' value={record['Tel. 2'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'Tel. 2')} placeholder='Tel. 2'></Input>
          </div>
          <div style={{width: '100%', marginBottom: 10}}>
            <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size='large' value={record['Email'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'Email')} placeholder='Email'></Input>
          </div>
          <Divider orientation="center" plain>Eigentümer Contact</Divider>
          <div style={{width: '100%', marginBottom: 10}}>
            <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size='large' value={record['Eingetumename'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'Eingetumename')} placeholder='Eigentümer Name'></Input>
          </div>
          <div style={{width: '100%', marginBottom: 10}}>
            <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size='large' value={record['EN tel. 1'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'EN tel. 1')} placeholder='Eigentümer Tel. 1'></Input>
          </div>
          <div style={{width: '100%', marginBottom: 10}}>
           <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size='large' value={record['EN tel. 2'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'EN tel. 2')} placeholder='Eigentümer Tel. 2'></Input>
          </div>
          <div style={{width: '100%', marginBottom: 10}}>
            <Input disabled={role==='Admin' || role==='Monitoring Team' ? false : true} size='large' value={record['EN Email'] || ''} onChange={(event) => handleDataChange(event.target.value, record.key, 'EN Email')} placeholder='Eigentümer Email'></Input>
          </div>

          <div style={{width: '100%'}}>
            {/* <Popconfirm  title="Are you sure?" okText="Confirm" icon={<QuestionCircleOutlined style={{ color: 'orange' }} />} onConfirm={() => updateClientStatus(record.key)} placement="bottom" >{record.GrundNA==='R0' ? <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} type='primary' danger size="large" style={{width: '100%'}}>Remove</Button> : <Button type='primary' size="large" style={{width: '100%'}}>Acitave</Button>}</Popconfirm> */}
            <Button disabled={role==='Admin' || role==='Monitoring Team' ? false : true} loading={updateLoading} iconPosition={'end'} size="large" type="primary" style={{width: '25%', minWidth: 150, backgroundColor: '#0AB39C'}} onClick={() => updateClientData(record.key)}>Update</Button>
          </div>
        
        </div>
      
      </div>
      },
    ];

    return (
      <Tabs
        style={{ margin: '10px', padding: '10px', minHeight: 600 }}
        tabPosition="left"
        items={tabItems}
        defaultActiveKey={role==='Call Centar' ? '9' : '1'}
      >
        {/* {tabItems.map(tab => (
          
          <TabPane tab={tab.label} key={tab.key} disabled={tab.disabled}>
            {tab.children}
          </TabPane>
        ))} */}
      </Tabs>
    );

  }

  export default expandedRowRender;