import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
// import { useIdleTimer } from 'react-idle-timer';
// import Login from "./components/Login"
// import Home from "./components/Home"
// import Accounts from "./components/Accounts";
// import Excel from "./components/Excel";
// import Repairs from "./components/Repairs";
// import Contacts from "./components/Contacts";
import Login from "./components/Login"
import Home from "./components/Home"
import Accounts from "./components/Accounts"
import Repairs from "./components/Repairs"
import Import from "./components/Import"
// import Contacts from "./components/Contacts"
import ProtectedRoute from './components/ProtectedRoute';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>} />
          {/* <Route path="/home" element={<Home/>} /> */}
          <Route path="/dashboard" element={<ProtectedRoute requiredRoles={['Admin', 'Monitoring Team', 'Call Centar']}><Home/></ProtectedRoute> } />
          <Route path="/accounts" element={<ProtectedRoute requiredRoles={['Admin']}><Accounts/></ProtectedRoute>} />
          <Route path="/repairs" element={<ProtectedRoute requiredRoles={['Admin', 'Monitoring Team']}><Repairs/></ProtectedRoute>} />
          {/* <Route path="/contacts" element={<ProtectedRoute><Contacts/></ProtectedRoute>} /> */}
          <Route path="/import" element={<ProtectedRoute requiredRoles={['Admin']}><Import/></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>{" "}
    </div>
  );
}

export default App;
