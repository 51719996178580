import React, { useEffect, useState, useRef } from "react";
import './css/Home.css'
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import "animate.css"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav'
import HeaderSection from "./Header";
import useNotifications from "./helpers/useNotifications";
import {
  WifiOutlined,
  HomeOutlined,
  UserOutlined,
  ToolOutlined,
  FileAddOutlined,
  LogoutOutlined,
  AppstoreTwoTone,
  SearchOutlined,
  FilterFilled,
  UserAddOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
  LockOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Avatar, Layout, Menu, theme, FloatButton, Input, Switch, Button, Select, List, Pagination, Tag, Popconfirm, Modal, Form, Divider } from 'antd';

const { Content, Footer } = Layout;
const { Option } = Select;


const Accounts = () => {

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalClients, setTotalClients] = useState(0)

  document.title = 'Accounts | RealBau Gmbh'

  const [collapsed, setCollapsed] = useState(true);

  const [changePasswordID, setChangePasswordID] = useState(0)

  const [form] = Form.useForm();

  const {
    token: { colorBgContainer, borderRadiusLG, colorText },
  } = theme.useToken();

  // const BACKEND_URL = 'http://localhost:8000'
    const BACKEND_URL = 'https://realbaugmbh.com/api'

    const navigate = useNavigate();

    const { notify, contextHolder } = useNotifications();

    const [logged, setLogged] = useState({
        name: '',
        surname: '',
        role: ''
    })

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('Admin')

    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [users, setUsers] = useState([]);

    const [modal, setModal] = useState(false);
    const [modalDel, setModalDel] = useState(false)
    const [modalPassword, setModalPassword] = useState(false)
    const [index, setIndex] = useState(null);

    const [loading, setLoading] = useState(false)

    const [searchString, setSearchString] = useState('')


    const wName = useRef(null);
    const wSurname = useRef(null);
    const wUsername = useRef(null);
    const wPassword = useRef(null);
    const wRole = useRef(null);


    useEffect(() => {

        const token = localStorage.getItem('usertoken')
    
        if(!token){
          navigate('/')
        } else{

            try {
                const decoded = jwtDecode(token)

                setLogged({
                    name: decoded.name,
                    surname: decoded.surname,
                    role: decoded.role
                })

            } catch(e) {
                console.error(e)
                navigate('/')
            }

            getUsers()
    
    
        }
    
      }, [navigate, searchString])


    //   const roleVerification = () => {

    //     console.log(logged.role)

    //     if(logged.role !== 'Admin') {
    //         navigate('/home')
    //     }


    //   }


      const getUsers = () => {

        // try {

            setLoading(true)

            axios
            .post(`${BACKEND_URL}/getUsers`, {searchString},  {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {
                
                setLoading(false)

                setUsers(response.data)
                
            })
            .catch((e) =>{
                if(e.response.status===403 || e.response.status===401){
                    // localStorage.removeItem('usertoken');
                    navigate('/dashboard')
                }
            }
            );

          
        // } catch (error) {
        //   console.error('Error fetching users:', error);
        // }
      };

      const handleOnIdle = () => {
        localStorage.removeItem('usertoken');
        // localStorage.setItem('expired', 'Session Expired');
        localStorage.setItem('expired', 'Logged out');
        navigate('/');
      };

      // const { getRemainingTime, getLastActiveTime } = useIdleTimer({
      //   timeout: 1000 * 60 * 30, // 30 min
      //   // timeout: 1000 * 30,
      //   onIdle: handleOnIdle,
      //   debounce: 500
      // });

      const toggleModal = () => {
        setModal(!modal);
      };

      const toggleModalDel = (index) => {
        setIndex(index)
        setModalDel(!modalDel);
      }

      const toggleModalPassword = (index) => {
        setIndex(index)
        setModalPassword(!modalPassword)
        setNewPassword('')
        setRepeatPassword('')
      }

      const addUser = (values) => {

            axios
            .post(`${BACKEND_URL}/addUser`, {
                name: values.Name.charAt(0).toUpperCase() + values.Name.slice(1).toLowerCase(),
                surname: values.Surname.charAt(0).toUpperCase() + values.Surname.slice(1).toLowerCase(),
                username: values.Username.toLowerCase(),
                password: values.Password,
                role: values.Role
            }, {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {
                // console.log(response.data)
                // setUsers(response.data)

                // setNotificationText("User successfully added")
                // setNotifyBackground('#b6ffb9e4')
                // handleShowNotification()

                notify.success('User added')

                setModal(false)

                getUsers()

                // setName('')
                // setSurname('')
                // setUsername('')
                // setPassword('')
                // setRole('')
                // getUsers()
                
            })
            .catch((e) =>{
                if(e.response.status===403 || e.response.status===401){
                    localStorage.removeItem('usertoken');
                    navigate('/')
                }
                console.log(e)

                notify.error('Failed to add user')
            }
            );

      }


      const deleteUser = (id) => {


        // try{
            axios
            .post(`${BACKEND_URL}/deleteUser`, {
                id
            }, {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {

              notify.error('Deleted user')

                getUsers()
                
            })
            .catch((e) =>{

                if(e.response.status===403 || e.response.status===401){
                    localStorage.removeItem('usertoken');
                    navigate('/')
                }
                console.log(e)

                notify.error('Failed to delete user')

            });

      }

    
    const changePassword = (values) => {

            // const id = users[index].ID
            const newPassword = values['New Password']

            // console.log(changePasswordID, "    ", newPassword)
            const id = changePasswordID

            axios
                .post(`${BACKEND_URL}/changePassword`, {
                    id,
                    newPassword
                }, {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {

                    // toggleModalPassword(null)
                    setModalPassword(false)

                    notify.success('Password changed')
                    
                })
                .catch((e) =>{
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }
                    console.log(e)

                    notify.error('Failed to change password')
                }
                );

    }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Nav defaultCollapsed={collapsed} collapsed={collapsed} setCollapsed={setCollapsed}/>

      {/* <Layout className="main-layout" style={{marginLeft: collapsed ? 80 : 200, transition: 'margin-left 0.2s'}}> */}
      <Layout className="main-layout" style={{marginLeft: 80}}>
        {/* <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed} logged={logged}/> */}
        <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed}/>

        <div className="page-info">
          <h3>Accounts {'>'}</h3>
        </div>

        {contextHolder}

        <FloatButton.BackTop />


        <Modal
        
        open={modal}
        title="Create new user"
        // okText="Create"
        // cancelText="Cancel"
        // okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
        onCancel={() => setModal(false)}
        destroyOnClose
        footer={null}
       
      >
        <Divider/>
        <Form

        layout="horizontal"
        form={form}
        name="form_add_user"
        initialValues={{ modifier: 'public' }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        clearOnDestroy
        onFinish={(values) => addUser(values)}
        // onFinish={(values) => onCreate(values)}  
        >
          <Form.Item name="Name" label="Name" rules={[{ required: true }, { pattern: /^[^\s]+$/, message: 'Name must not contain spaces' }]} style={{marginTop: 30}}>
            <Input placeholder="Name"/>
          </Form.Item>
          <Form.Item name="Surname" label="Surname" rules={[{ required: true }, { pattern: /^[^\s]+$/, message: 'Surname must not contain spaces' }]}>
            <Input placeholder="Surname"/>
          </Form.Item>
          <Form.Item name="Username" label="Username" rules={[{ required: true }, { pattern: /^[^\s]+$/, message: 'Username must not contain spaces' }]}>
            <Input placeholder="Username"/>
          </Form.Item>
          <Form.Item name="Password" label="Password" rules={[{ required: true }]}>
            <Input type="password" placeholder="Password"/>
          </Form.Item>
          <Form.Item name="Role" label="Role" rules={[{ required: true }]}>
            <Select
            placeholder="Select Role"
            // onChange={onGenderChange}
            allowClear
            // onChange={(event) => setVomRole(event.target.value)}
            >
              <Option value="Admin">Admin</Option>
              <Option value="Monitoring Team">Monitoring Team</Option>
              <Option value="Call Centar">Call Centar</Option>
            </Select>
          </Form.Item>
          <Form.Item style={{margin: 'auto'}} wrapperCol={{ span: 14, offset: 14 }}>
            <Button htmlType="reset">reset</Button>
            <Button style={{backgroundColor: '#0AB39C', marginLeft: 10}} type="primary" htmlType="submit">Create</Button>
          </Form.Item>
        </Form>
      </Modal>

        <Modal
        
        open={modalPassword}
        title="Change Password"
        okText="Change"
        cancelText="Cancel"
        okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
        onCancel={() => setModalPassword(false)}
        destroyOnClose
        footer={null}
       
      >
        <Divider/>
        <Form

        layout="vertical"
        form={form}
        name="form_change_password"
        initialValues={{ modifier: 'public' }}
        clearOnDestroy
        // layout='horizontal'
        // labelCol={{ span: 10 }}
        wrapperCol={{ span: 24 }}
        // onFinish={(values) => addNewVom(values)} 
        onFinish={(values) => changePassword(values)}  
        >
          <Form.Item name="New Password" label="New Password" rules={[{ required: true, message: 'Please input your new password!' }]} style={{marginTop: 30}}>
            <Input type="password" placeholder="New Password"/>
          </Form.Item>
          <Form.Item name="Repeat New Password" label="Repeat New Password"  dependencies={['New Password']}
        rules={[
          { required: true, message: 'Please repeat your new password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('New Password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords do not match!'));
            },
          }),
        ]}>
            <Input type="password" placeholder="Repeat New Password"/>
          </Form.Item>
          <Form.Item style={{margin: 'auto'}} wrapperCol={{ span: 24, offset: 16 }}>
            <Button htmlType="reset">reset</Button>
            <Button style={{backgroundColor: '#0AB39C', marginLeft: 10}} type="primary" htmlType="submit">Change</Button>
          </Form.Item>
        </Form>
      </Modal>

        <Content
          style={{
            margin: '24px 16px',
          }}
        >

        <div className="filter-container"
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              marginBottom: 20
            }}
          >
            <div className="main-filters">
              {/* <Input allowClear className="search-input" placeholder="Search" prefix={<SearchOutlined />}  onChange={(event) => 
              {
                // setSearchString(event.target.value)
                // handleChange(event)
                // setCurrentPage(1)
              }} /> */}


              <Form

              layout="vertical"
              form={form}
              name="form_in_modal"
              // initialValues={{ modifier: 'public' }}
              clearOnDestroy
              // layout='horizontal'
              // labelCol={{ span: 10 }}
              // wrapperCol={{ span: 24 }}
              // onFinish={(values) => addNewVom(values)} 
              // onFinish={(values) => changePassword(values)}
              onValuesChange={(values) => setSearchString(values.Search)}
              style={{width: '40%'}}  
              >
                <Form.Item name="Search">
                  <Input allowClear placeholder="Search" prefix={<SearchOutlined />}/>
                </Form.Item>
              </Form>

              <Button size="large" type="primary" icon={<UserAddOutlined />} onClick={() => setModal(true)} style={{
                // backgroundImage: 'linear-gradient(135deg, #6253E1, #04BEFE)',
                // backgroundColor: '#405189'
                fontSize: 14,
                backgroundColor: '#0AB39C'
                }}>
                New User
              </Button>
            </div>
            
          </div>


          <List
            pagination={{ position: 'bottom', align: 'center' }}
            itemLayout="horizontal"
            bordered
            loading={loading}
            style={{backgroundColor: 'white', borderColor: 'white'}}
            // size="small"
            dataSource={users}
            renderItem={(item, index) => (
              <List.Item actions={[<a key="list-loadmore-edit" onClick={() => {
                setModalPassword(true) 
                setChangePasswordID(item.ID)
                }}><LockOutlined /></a>, <Popconfirm  title="Are you sure?" okText="Delete" onConfirm={() => deleteUser(item.ID)}><a><DeleteOutlined /></a></Popconfirm>]}>
                <List.Item.Meta
                  avatar={<Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{item.Name[0]}{item.Surname[0]}</Avatar>}
                  title={<a>{item.Name} {item.Surname}</a>}
                  description={item.Username}
                />
                <Tag color="blue">{item.Role}</Tag>
              </List.Item>
            )}
          />



          {/* <div className="pagination-container">
            <div className="total-items">
              {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalClients)} of ${totalClients} items`}
            </div>
            <Pagination
              total={totalClients}
              defaultPageSize={itemsPerPage}
              defaultCurrent={currentPage}
              responsive
              onChange={(page) => {
                setCurrentPage(page)
                window.scrollTo({ top: 300 })
              }}
              pageSize={itemsPerPage}
              current={currentPage}
              showSizeChanger={false}
            />
            <div className="page-size-selector">
              <Select defaultValue={itemsPerPage} value={itemsPerPage} onChange={(size) => {
                setItemsPerPage(size)
                setCurrentPage(1)
                window.scrollTo({ top: 300 })
              }}>
                <Option value={1}>1 rows/page</Option>
                <Option value={5}>5 rows/page</Option>
                <Option value={10}>10 rows/page</Option>
                <Option value={20}>20 rows/page</Option>
                <Option value={50}>50 rows/page</Option>
                <Option value={100}>100 rows/page</Option>
              </Select>
            </div>
          </div> */}
        </Content>

        
        <Footer
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
            fontSize: 13,
          }}
        >
          ©{new Date().getFullYear()} Powered by DHM Projekt
        </Footer>
      </Layout>
    </Layout>
  );


}

export default Accounts;