import React, { useEffect, useState, useRef, useCallback, Children } from "react";
import './css/Home.css'
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import "animate.css"
import { useNavigate } from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExcelJS from 'exceljs';
import Nav from './Nav';
import HeaderSection from "./Header";
import useNotifications from "./helpers/useNotifications";
import expandedRowRender from './helpers/expandedRowRender';
import debounce from 'lodash/debounce';
import {
  WifiOutlined,
  HomeOutlined,
  UserOutlined,
  ToolOutlined,
  FileAddOutlined,
  LogoutOutlined,
  AppstoreTwoTone,
  SearchOutlined,
  FilterFilled,
  DownloadOutlined,
  FilterOutlined,
  CopyOutlined,
  CommentOutlined,
  DeleteOutlined,
  ExpandAltOutlined,
  PhoneFilled,
  PhoneOutlined,
  PlusOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme, FloatButton, Input, Switch, Button, Table, Space, Tag, Pagination, Select, Radio, Tabs, Divider, Checkbox, InputNumber, Card, message, Tooltip, Form, Modal, List, Popconfirm } from 'antd';
const { Option } = Select;
const { Content, Footer } = Layout;
const { TextArea } = Input;
const { TabPane } = Tabs;
// import enUS from 'antd/locale/en_US';
// import zhCN from 'antd/locale/zh_CN';
// import dayjs from 'dayjs';
// import 'dayjs/locale/zh-cn';
// dayjs.locale('en');


const Home = () => {

  const debouncedHandleChange = useCallback(
    debounce((value) => {
      setSearchString(value);
      setCurrentPage(1)
    }, 100), // Adjust the delay as needed
    []
  );

  const handleChange = (event) => {
    debouncedHandleChange(event.target.value);
  };

  const [currrentRecord, setCurrentRecord] = useState(null)
  
  const [form] = Form.useForm();

  const {
    token: { colorBgContainer, borderRadiusLG, colorText },
  } = theme.useToken();

  // const BACKEND_URL = 'http://localhost:8000'
    const BACKEND_URL = 'https://realbaugmbh.com/api'

    const navigate = useNavigate();

    const { notify, contextHolder } = useNotifications();

    const [prom, setProm] = useState(true)

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [logged, setLogged] = useState({
        name: '',
        surname: '',
        role: ''
    })

    const [heightFilter, setHeightFilter] = useState(0)
    const [displayFilter, setDisplayFilter] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    

    const [personalFilter, setPersonalFilter] = useState('none')
    const [locationFilter, setLocationFilter] = useState('none')
    const [vomFilter, setVomFilter] = useState('none')
    const [finishFilter, setFinishFilter] = useState('none')

    const [expandedRowIndex, setExpandedRowIndex] = useState(null)
    const [expandedRowHeight, setExpandedRowHeight] = useState('0px')

    const [searchString, setSearchString] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [gfChecked, setGfChecked] = useState(false);

    const [voms, setVoms] = useState([])
    const [hbVoms, setHbVoms] = useState([])
    const [tfbVoms, setTfbVoms] = useState([])
    // const [gbVoms, setGbVoms] = useState([])
    const [faserVoms, setFaserVoms] = useState([])
    const [maVoms, setMaVoms] = useState([])
    const [popNvtVoms, setPopNvtVoms] = useState([])

    const [activeChecked, setActiveChecked] = useState(true)
    const [nonActiveChecked, setNonActiveChecked] = useState(false)

    const [modalCom, setModalCom] = useState(false);

    const textAreaRef = useRef(null);

    const [modalVom, setModalVom] = useState(false);
    const [modalEing, setModalEing] = useState(false)
    const [vomName, setVomName] = useState('')
    const [vomSurname, setVomSurname] = useState('')
    const [vomRole, setVomRole] = useState('')

    const [indexToCom, setIndexToCom] = useState(null);
    const [commentRep, setCommentRep] = useState('')
    const [commentNR, setCommentNR] = useState('')
    const [callReason, setCallReason] = useState(null)
    const [callResult, setCallResult] = useState(null)
    const [selectedCallOption, setSelectedCallOption] = useState('');
    const labelsCall = {
        hb_machen: useRef(null),
        m_machen: useRef(null),
        a_machen: useRef(null),
        hb_nicht: useRef(null),
        m_nicht: useRef(null),
        a_nicht: useRef(null),
        specific_time: useRef(null),
      };

    const [displayMoreFilters, setDisplayMoreFilters] = useState(false)

    const [isDoneFilters, setIsDoneFilters] = useState({
        hbIsDone: 'both',
        tfbDone: 'both',
    })
    const [hbDoneFilter, setHbDoneFilter] = useState('')
    const [tfbDoneFilter, setTfbDoneFilter] = useState('')
    const [gbDoneFilter, setGbDoneFilter] = useState('')
    const [hkDoneFilter, setHkDoneFilter] = useState('')
    const [vzkDoneFilter, setVzkDoneFilter] = useState('')
    const [mDoneFilter, setMDoneFilter] = useState('')
    const [aktDoneFilter, setAktDoneFilter] = useState('')
    const [nvtDoneFilter, setNvtDoneFilter] = useState('')
    const [popDoneFilter, setPopDoneFilter] = useState('')


    const [rows, setRows] = useState([])
    const [rowsCopy, setRowsCopy] = useState([])

    const [showNotification, setShowNotification] = useState(false);
    const [notifyBackground, setNotifyBackground] = useState('#e8e8e8e2');
    const [notificationText, setNotificationText] = useState('')
    const [loaderDisplay, setLoaderDisplay] = useState('none')

    const [waitingAxios, setWaitingAxios] = useState(false)

    const [loading, setLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [totalClients, setTotalClients] = useState(0)

    const [hbKomment, setHbKomment] = useState('')
    const [gbKomment, setGbKomment] = useState('')
    const [fmaKomment, setFmaKomment] = useState('')

    // const [callStatus, setCallStatus] = useState('#41cf3a')
    // const [callStatus, setCallStatus] = useState('#16BC04')
    // const [callStatus, setCallStatus] = useState('#16BC04')

    const [callHistory, setCallHistory] = useState([])
    const [callDisable, setCallDisable] = useState(false)

    const [newReparatura, setNewReparatura] = useState('')

    const personalRef = useRef(null);
    const searchRef = useRef(null);
    const gfCheckedRef = useRef(null)
    // const hbKomment = useRef(null)
    const gartenborungKom = useRef(null)
    // const fmaKomment = useRef(null)

    const tableRef = useRef(null);

    // const [collapsed, setCollapsed] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    document.title = 'Dashboard | RealBau Gmbh'

    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'name',
        className: 'min-width-column',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: 'Tel 1',
        dataIndex: 'Tel. 1',
        key: 'tel-1',
        className: 'min-width-column',
        render: (text) => <Tooltip title="Copied" trigger='focus'>{text && <Button type="text" size="small" className="copy" onClick={() => navigator.clipboard.writeText(text)}>{text}</Button>}</Tooltip>
      },
      {
        title: 'Tel 2',
        dataIndex: 'Tel. 2',
        key: 'tel-2',
        className: 'min-width-column',
        render: (text) => <Tooltip title="Copied" trigger='focus'>{text && <Button type="text" size="small" className="copy" onClick={() => navigator.clipboard.writeText(text)}>{text}</Button>}</Tooltip>
        // responsive: ['lg'],
      },
      {
        title: 'Email',
        dataIndex: 'Email',
        key: 'email',
        className: 'min-width-column',
        render: (text) => <Tooltip title="Copied" trigger='focus'>{text && <Button type="text" size="small" className="copy" onClick={() => navigator.clipboard.writeText(text)}>{text}</Button>}</Tooltip>
        // render: (_, { tags }) => (
        //   <>
        //     {tags.map((tag) => {
        //       let color = tag.length > 5 ? 'geekblue' : 'green';
        //       if (tag === 'loser') {
        //         color = 'volcano';
        //       }
        //       return (
        //         <Tag color={color} key={tag}>
        //           {tag.toUpperCase()}
        //         </Tag>
        //       );
        //     })}
        //   </>
        // ),
      },
      gfChecked ? {
        title: 'Postal code',
        dataIndex: 'Zip code',
        key: 'Postal code',
        className: 'min-width-column',
      } : {
        title: 'City',
        dataIndex: 'City',
        key: 'city',
        className: 'min-width-column',
        // render: (_, record) => (
        //   <Space size="middle">
        //     <a>Invite {record.name}</a>
        //     <a>Delete</a>
        //   </Space>
        // ),
      },
      {
        title: 'Street',
        key: 'street',
        dataIndex: 'Street',
        className: 'min-width-column',
      },
      {
        title: 'H.N.',
        key: 'hn',
        dataIndex: 'H.N.',
        className: 'min-width-column',
      },
      {
        title: 'H.Z.',
        key: 'hz',
        dataIndex: 'H.Z.',
        className: 'min-width-column',
      },
      {
        title: 'Unit',
        key: 'unit',
        dataIndex: 'Unit',
        className: 'min-width-column',
        render: (_, record) => logged.role==='Admin' || logged.role==='Monitoring Team' ? <InputNumber style={{width: 50}} min={0} value={rowsCopy[record.key]['Unit'] || ''} size="small" onChange={(value) => updateUnit(value, record)}/> : rowsCopy[record.key]['Unit'] || '',
      },
      {
        title: '',
        key: 'phone',
        dataIndex: 'Phone',
        className: 'min-width-column',
        render: (_, record) => <div style={{display: 'flex', justifyContent: 'center'}}><PhoneOutlined style={{color: record.Kunde!==1 && record.GrundNA !== 'R0' ? 'red' : record['NR Specific']===0 ? '#0AB39C' : '#F7D400', fontSize: 20}}/></div>,
      },
      {
        title: '',
        key: 'eingentumer',
        dataIndex: 'eingentumer',
        className: 'min-width-column',
        render: (_, record) => <div style={{display: 'flex', justifyContent: 'center'}}><ContactsOutlined onClick={() => {
          setModalEing(true)
          setCurrentRecord(record)
        }} style={{color: '#1677FF', cursor: 'pointer', fontSize: 17}}/></div>,
      },
    ];

    


    useEffect(() => {


        const token = localStorage.getItem('usertoken')
    
        // if(!token){
        //   navigate('/')
        // } else{


            try {
                const decoded = jwtDecode(token)

                setLogged({
                    name: decoded.name,
                    surname: decoded.surname,
                    role: decoded.role
                })

            } catch(e) {
                console.error(e)
                navigate('/')
            }

            let gf = 'gf_plus'

            if(gfChecked===true){
                gf = 'deutsche_gf'
            }

            // if(hbDoneFilter)
    

            setLoading(true)

            axios
            .post(`${BACKEND_URL}/getData`, {
                currentPage,
                itemsPerPage,
                searchString,
                activeChecked,
                nonActiveChecked,
                hbDoneFilter,
                tfbDoneFilter,
                gbDoneFilter,
                hkDoneFilter,
                vzkDoneFilter,
                mDoneFilter,
                aktDoneFilter,
                nvtDoneFilter,
                popDoneFilter,
                gf
            }, {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {

                // setLoaderDisplay("none")
                setLoading(false)

                setHbKomment('')
                setGbKomment('')
                setFmaKomment('')
                setNewReparatura('')

                const dataWithKeys = response.data.results.map((row, index) => ({
                  ...row,
                  key: index, // Koristimo indeks kao ključ (možete koristiti neki jedinstveni atribut ako postoji)
                }));
                
                // console.log(response.data)
                setRows(dataWithKeys)
                // setRowsCopy(response.data)
                setRowsCopy(cloneDeep(dataWithKeys))
                setTotalClients(response.data.totalRows)
                // setIncomes(response.data[0].total)
                
            })
            .catch((e) =>{
                // if((e.response && (e.response.status===403 || e.response.status===401)) || !e.response){
                //     navigate('/')
                // }
                if(e.response){
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }
                    console.log(e)
                } else {
                    localStorage.removeItem('usertoken');
                    navigate('/')
                }
                
            }
            );


            axios
            .get(`${BACKEND_URL}/getVoms`,  {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {
                
                // console.log(response.data)
                setVoms(response.data)
                setHbVoms(response.data.filter(user => user.Role === 'Hausbegehung'))
                setTfbVoms(response.data.filter(user => user.Role === 'Tiefbau'))
                setFaserVoms(response.data.filter(user => user.Role === 'Faser'))
                setMaVoms(response.data.filter(user => user.Role === 'Montage & Aktievirung'))
                setPopNvtVoms(response.data.filter(user => user.Role === 'PoP/NvT Spleißen'))

                // setRowsCopy(response.data)
                // setRowsCopy(cloneDeep(response.data))
                // setIncomes(response.data[0].total)
                
            })
            .catch((e) =>{
                // if((e.response && (e.response.status===403 || e.response.status===401)) || !e.response){
                //     navigate('/')
                // }
                if(e.response){
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }
                    console.log(e)
                } else {
                    localStorage.removeItem('usertoken');
                    navigate('/')
                }
            }
            );
    
    
    
        // }
    
      }, [navigate, currentPage, itemsPerPage, searchString, gfChecked, activeChecked, nonActiveChecked, prom, hbDoneFilter, tfbDoneFilter, gbDoneFilter, hkDoneFilter, vzkDoneFilter, mDoneFilter, aktDoneFilter, nvtDoneFilter, popDoneFilter])


      const downloadExcel = () => {

        const downloadRow = [cloneDeep([...rows])];


        downloadRow.forEach(client => {
            delete client.ID
            // delete client.Kunde
            delete client.Location
            delete client['EF/MF']
            delete client['ET/MT']
            delete client['WE HBG']
            delete client.Updated

            if(client['Reparatura Done'] === 0) client['Reparatura Done'] = 'Nein'
            else client['Reparatura Done'] = 'Ja'

            if(client['NR Active'] === 0) client['NR Active'] = 'Nein'
            else client['NR Active'] = 'Ja'

            if(client['NR Specific'] === 0) client['NR Specific'] = 'Nein'
            else client['NR Specific'] = 'Ja'

            if(client['Kunde'] === 0) client['Kunde'] = 'Nein'
            else client['Kunde'] = 'Ja'
        })

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Dodajte podatke u radni list
        worksheet.columns = Object.keys(rows[0]).map((key) => ({ header: key, key: key, width: 25 }));

        // Dodajte redove u radni list
        rows.forEach(row => {
            worksheet.addRow(row);
        });

        // Stilizujte zaglavlja kolona
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                // fgColor: { argb: 'FF0000FF' } // Plava boja
                fgColor: { argb: '405189' }
            };
            cell.font = {
                color: { argb: 'FFFFFFFF' }, // Bela boja fonta
                bold: true
            };
        });

        // worksheet.eachRow((row, rowNumber) => {
        //     // Preskočite zaglavlje
        //     if (rowNumber === 1) return;
          
        //     row.eachCell((cell, colNumber) => {
        //       if (cell.value === 'Ja') {
        //         cell.fill = {
        //           type: 'pattern',
        //           pattern: 'solid',
        //           fgColor: { argb: 'FF00FF00' } // Zelena boja
        //         };
        //       } else if (cell.value === 'Nein') {
        //         cell.fill = {
        //           type: 'pattern',
        //           pattern: 'solid',
        //           fgColor: { argb: 'FFFF0000' } // Crvena boja
        //         };
        //       }
        //     });
        //   });

        // Sačuvajte radnu knjigu u XLSX fajl
        workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export.xlsx';
        a.click();
            window.URL.revokeObjectURL(url);
        });
      }

      const handleDataChange = (value, key, name) => {
        // const { name, value } = event.target;
        

        // console.log(name, "  ", value, " ", index)
        
        const updatedRowData = [...rowsCopy];
        updatedRowData[key][name] = value;
        // console.log("OVO JE UPDATED: ", updatedRowData)
        // console.log("OVO JE ROWS POSLE UPDATE: ", rows)
        setRowsCopy(updatedRowData);
        // setRowsCopy((prevRowsCopy) => {
        //     return [...prevRowsCopy, ...updatedRowData];
        //   });
      };

      const updateUnit = (value, record) => {


        handleDataChange(value, record.key, 'Unit')

        const id = rows[record.key]['ID']
        const unitValue = value

        let gf = 'gf_plus'

        if(gfChecked===true){
            gf = 'deutsche_gf'
        }

        axios
                .post(`${BACKEND_URL}/updateUnit`, 
                {
                    id,
                    unitValue,
                    gf
                },  {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {
                    
                    // console.log(response)

                    setRows(cloneDeep([...rowsCopy]))

                    notify.success('Updated');
                    
                })
                .catch((e) =>{
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }

                    notify.error('Failed to update');
                }
                );

      }

      const onExpand = (expanded, record) => {
        setExpandedRowKeys(expanded ? [record.key] : []);
        setHbKomment('')
        setGbKomment('')
        setFmaKomment('')
        setNewReparatura('')
        setCommentNR('')
        setCallReason(null)
        setCallResult(null)
      };


      const updateClientData = (index) => {

        const stringArray1 = JSON.stringify(rows);
        const stringArray2 = JSON.stringify(rowsCopy);

        if(stringArray1 === stringArray2 && !hbKomment && !gbKomment && !fmaKomment && !newReparatura){

            notify.warning('There is no changes')

        } else {
            // setRows(cloneDeep([...rowsCopy]));

            let updatedRowData = [...rowsCopy]

            if(hbKomment){
              const newComment = {
                name: logged.name + ' ' + logged.surname,
                // date: new Date().toISOString().split('T')[0],
                date: new Date().toLocaleString('en-US', { timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric' }),
                comment: hbKomment
              }

              const comment = updatedRowData[index]["HB Komment"] || '[]'
              const updatedComment = JSON.parse(comment)
              updatedComment.push(newComment)
              updatedRowData[index]["HB Komment"] = JSON.stringify(updatedComment)

              
              // if(updatedRowData[index]["HB Komment"] === null) {
              //     // updatedRowData[index]["HB Komment"] = logged.name + ' ' + logged.surname + ' (' + new Date().toISOString().split('T')[0] + ') : ' + hbKomment + '##'
                  
              //     updatedRowData[index]["HB Komment"] = JSON.stringify([newComment])
              // } else {
              //     // updatedRowData[index]["HB Komment"] += logged.name + ' ' + logged.surname + ' (' + new Date().toISOString().split('T')[0] + ') : ' + hbKomment + '##'
              //     const comments = JSON.parse(updatedRowData[index]["HB Komment"])
              //     comments.push(newComment)
              //     updatedRowData[index]["HB Komment"] = JSON.stringify(comments)
              // }
                
            }

            if(gbKomment){
              const newComment = {
                name: logged.name + ' ' + logged.surname,
                // date: new Date().toISOString().split('T')[0],
                date: new Date().toLocaleString('en-US', { timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric' }),
                comment: gbKomment
              }

              const comment = updatedRowData[index]["Gartenborung Komment"] || '[]'
              const updatedComment = JSON.parse(comment)
              updatedComment.push(newComment)
              updatedRowData[index]["Gartenborung Komment"] = JSON.stringify(updatedComment)
            }

            if(fmaKomment){
              const newComment = {
                name: logged.name + ' ' + logged.surname,
                // date: new Date().toISOString().split('T')[0],
                date: new Date().toLocaleString('en-US', { timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric' }),
                comment: fmaKomment
              }

              const comment = updatedRowData[index]["FMA Komment"] || '[]'
              const updatedComment = JSON.parse(comment)
              updatedComment.push(newComment)
              updatedRowData[index]["FMA Komment"] = JSON.stringify(updatedComment)
            }

            if(newReparatura) {
              const newRep = {
                name: logged.name + ' ' + logged.surname,
                // date: new Date().toISOString().split('T')[0],
                date: new Date().toLocaleString('en-US', { timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric' }),
                comment: newReparatura
              }

              const reparatura = updatedRowData[index]["Reparatura"] || '[]'
              const updatedReparatura = JSON.parse(reparatura)
              updatedReparatura.push(newRep)
              updatedRowData[index]["Reparatura"] = JSON.stringify(updatedReparatura)
            }
            
            setRowsCopy(updatedRowData);

            rowsCopy[index]['NAS'] = 'Nein'

            if((rowsCopy[index]['HB Year'] && !rowsCopy[index]['Gartenborung Year']) || (rowsCopy[index]['HB Week'] && !rowsCopy[index]['Gartenborung Week'])){
                rowsCopy[index]['NAS'] = 'Ja'
            } else if(rowsCopy[index]['HB Year'] && rowsCopy[index]['Gartenborung Year'] && parseInt(rowsCopy[index]['HB Year']) < parseInt(rowsCopy[index]['Gartenborung Year'])){
                rowsCopy[index]['NAS'] = 'Ja'
            } else if(rowsCopy[index]['HB Year'] && rowsCopy[index]['HB Week'] && rowsCopy[index]['Gartenborung Year'] && rowsCopy[index]['Gartenborung Week'] && (parseInt(rowsCopy[index]['HB Year']) === parseInt(rowsCopy[index]['Gartenborung Year'])) && (parseInt(rowsCopy[index]['HB Week']) <= parseInt(rowsCopy[index]['Gartenborung Week']))){
                rowsCopy[index]['NAS'] = 'Ja'
            }

            // rowsCopy[index]['HP+'] = 'Nein'

            // if(rowsCopy[index]['Tiefbau Week'] && rowsCopy[index]['Tiefbau Year']) rowsCopy[index]['HP+'] = 'Ja'

            // if(parseInt(rowsCopy[index]['HB Year']) && parseInt(rowsCopy[index]['Gartenborung Year'])){

            // }   

            // try{



            let gf = 'gf_plus'

            if(gfChecked===true){
                gf = 'deutsche_gf'
            }

            updatedRowData = [...rowsCopy]

            rowsCopy[index]['HB Week'] !== null && rowsCopy[index]['HB Year'] !== null && rowsCopy[index]['HB Week'] !== '' && rowsCopy[index]['HB Year'] !== '' ? updatedRowData[index]['HB Done'] = 1 : updatedRowData[index]['HB Done'] = 0
            rowsCopy[index]['Tiefbau Week'] !== null && rowsCopy[index]['Tiefbau Year'] !== null && rowsCopy[index]['Tiefbau Week'] !== '' && rowsCopy[index]['Tiefbau Year'] !== '' ? updatedRowData[index]['Tiefbau Done'] = 1 : updatedRowData[index]['Tiefbau Done'] = 0
            rowsCopy[index]['HK Faser Week'] !== null && rowsCopy[index]['HK Faser Year'] !== null && rowsCopy[index]['HK Faser Week'] !== '' && rowsCopy[index]['HK Faser Year'] !== '' ? updatedRowData[index]['HK Faser Done'] = 1 : updatedRowData[index]['HK Faser Done'] = 0
            rowsCopy[index]['VzK Faser Week'] !== null && rowsCopy[index]['VzK Faser Year'] !== null && rowsCopy[index]['VzK Faser Week'] !== '' && rowsCopy[index]['VzK Faser Year'] !== '' ? updatedRowData[index]['VzK Faser Done'] = 1 : updatedRowData[index]['VzK Faser Done'] = 0
            rowsCopy[index]['Montage Week'] !== null && rowsCopy[index]['Montage Year'] !== null && rowsCopy[index]['Montage Week'] !== '' && rowsCopy[index]['Montage Year'] !== '' ? updatedRowData[index]['Montage Done'] = 1 : updatedRowData[index]['Montage Done'] = 0
            rowsCopy[index]['Aktievirung Week'] !== null && rowsCopy[index]['Aktievirung Year'] !== null && rowsCopy[index]['Aktievirung Week'] !== '' && rowsCopy[index]['Aktievirung Year'] !== '' ? updatedRowData[index]['Aktievirung Done'] = 1 : updatedRowData[index]['Aktievirung Done'] = 0

            if(gf === 'gf_plus'){
                rowsCopy[index]['Gartenborung Week'] !== null && rowsCopy[index]['Gartenborung Year'] !== null && rowsCopy[index]['Gartenborung Week'] !== '' && rowsCopy[index]['Gartenborung Year'] !== '' ? updatedRowData[index]['Gartenborung Done'] = 1 : updatedRowData[index]['Gartenborung Done'] = 0
                rowsCopy[index]['NvT Spleißen Week'] !== null && rowsCopy[index]['NvT Spleißen Year'] !== null && rowsCopy[index]['NvT Spleißen Week'] !== '' && rowsCopy[index]['NvT Spleißen Year'] !== '' ? updatedRowData[index]['NvT Spleißen Done'] = 1 : updatedRowData[index]['NvT Spleißen Done'] = 0
                rowsCopy[index]['PoP Spleißen Week'] !== null && rowsCopy[index]['PoP Spleißen Year'] !== null && rowsCopy[index]['PoP Spleißen Week'] !== '' && rowsCopy[index]['PoP Spleißen Year'] !== '' ? updatedRowData[index]['Pop Spleißen Done'] = 1 : updatedRowData[index]['Pop Spleißen Done'] = 0
                rowsCopy[index]['Vermessung HA Week'] !== null && rowsCopy[index]['Vermessung HA Year'] !== null && rowsCopy[index]['Vermessung HA Week'] !== '' && rowsCopy[index]['Vermessung HA Year'] !== '' ? updatedRowData[index]['Vermessung HA Done'] = 1 : updatedRowData[index]['Vermessung HA Done'] = 0
                rowsCopy[index]['Vermessung HP+ Week'] !== null && rowsCopy[index]['Vermessung HP+ Year'] !== null && rowsCopy[index]['Vermessung HP+ Week'] !== '' && rowsCopy[index]['Vermessung HP+ Year'] !== '' ? updatedRowData[index]['Vermessung HP+ Done'] = 1 : updatedRowData[index]['Vermessung HP+ Done'] = 0
            } else {
              rowsCopy[index]['Vermessung Week'] !== null && rowsCopy[index]['Vermessung Year'] !== null && rowsCopy[index]['Vermessung Week'] !== '' && rowsCopy[index]['Vermessung Year'] !== '' ? updatedRowData[index]['Vermessung Done'] = 1 : updatedRowData[index]['Vermessung Done'] = 0
            }


            setRowsCopy(updatedRowData)

            // setWaitingAxios(true)
            setUpdateLoading(true)

                axios
                .post(`${BACKEND_URL}/updateClient`, {
                    rows: rowsCopy[index],
                    gf,
                }
                ,  {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {
                    
                    setUpdateLoading(false)

                    setHbKomment('')
                    setGbKomment('')
                    setFmaKomment('')
                    setNewReparatura('')

                    
                    // gartenborungKom.current.value = ''
                    // fmaKomment.current.value = ''
                    // setRows(response.data)
                    // setRowsCopy(response.data)
                    setRows(cloneDeep([...rowsCopy]))

                    setProm(!prom)
                    // textAreaRef.current.resizableTextArea.textArea.value = '';
                    // const rows = document.getElementsByTagName('tr');
                    // const targetRow = rows[index];
                    // targetRow.scrollIntoView();


                    notify.success('Updated')

                    
                })
                .catch((e) =>{
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }

                    setUpdateLoading(false)

                    setRowsCopy(cloneDeep([...rows]))

                    notify.error('Failed to update')

                }
                );


                // } catch (error) {
                    // console.error('Error fetching suggestions:', error);
                // }

        }

      }

      const handleCallClient = (key) => {

        if(!callReason || !callResult) {
            notify.warning('No selected')
        } 
        // else if (!commentNR){
        //   notify.warning('No selected')
        // } 
        else {

          let updatedRowData = [...rowsCopy]

            let gf = 'gf_plus'

            if(gfChecked===true){
                gf = 'deutsche_gf'
            }
            
            const id = updatedRowData[key]['ID']

            // let newComment = ''

            // if(rows[indexToCom]['NR']){
            //     if(commentRep !== ''){
            //         newComment = rows[indexToCom]['NR'] + logged.name + ' ' + logged.surname + ' (' + new Date().toISOString().split('T')[0] + ') (' + selectedCallOption + ') : ' + commentRep + '##'
            //     } else {
            //         newComment = rows[indexToCom]['NR'] + logged.name + ' ' + logged.surname + ' (' + new Date().toISOString().split('T')[0] + ') : ' + selectedCallOption + '##'
            //     }
                
            // } else {
            //     if(commentRep !== ''){
            //         newComment = logged.name + ' ' + logged.surname + ' (' + new Date().toISOString().split('T')[0] + ') (' + selectedCallOption + ') : ' + commentRep + '##'
            //     } else {
            //         newComment = logged.name + ' ' + logged.surname + ' (' + new Date().toISOString().split('T')[0] + ') : ' + selectedCallOption + '##'
            //     }
            // }

            // if(commentNR){
              var newComment = {
                name: logged.name + ' ' + logged.surname,
                // date: new Date().toISOString().split('T')[0],
                date: new Date().toLocaleString('en-US', { timeZone: 'CET', year: 'numeric', month: 'long', day: 'numeric' }),
                callResult: callResult,
                callReason: callReason,
                comment: commentNR
              }

              const comment = updatedRowData[key]["NR"] || '[]'
              const updatedComment = JSON.parse(comment)
              updatedComment.push(newComment)
              updatedRowData[key]["NR"] = JSON.stringify(updatedComment)
              const newNR = updatedRowData[key]["NR"]
            // }

              let nrSpecific = updatedRowData[key]["NR Specific"]
              if(callResult === 'Specific Time'){
                nrSpecific = 1
              }
        
                axios
                    .post(`${BACKEND_URL}/updateNR`, 
                    {
                        id,
                        newNR,
                        nrSpecific,
                        gf
                    },  {
                        headers: {
                        Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                        'Content-Type': 'application/json',
                        }})
                    .then((response) => {

                        // toggleModalCom(null)
                        setCommentNR('')
                        setCallReason(null)
                        setCallResult(null)

                        setProm(!prom)

                        // const updatedRowData2 = [...rowsCopy]
                        // updatedRowData2[key]['NR'] = newNR
                        // // updatedRowData[indexToCom]['NR Active'] = activeNR

                        // setRowsCopy(updatedRowData2)
                        setRows(cloneDeep([...rowsCopy]))

                        notify.success("Updated")
                        
                    })
                    .catch((e) =>{
                      console.log("ERRRR : ", e)
                      if(e.response){
                        if(e.response.status===403 || e.response.status===401){
                          localStorage.removeItem('usertoken');
                          navigate('/')
                        }
                      }
                        
                      setProm(!prom)

                      notify.error("Failed to Update")
        
                    });
        }


      }


      const removeSpecificCall = (index) => {


            const id = rowsCopy[index]['ID']

            let gf = 'gf_plus'

            if(gfChecked===true){
                gf = 'deutsche_gf'
            }

            
            axios
                .post(`${BACKEND_URL}/specificNR`, 
                {
                    id,
                    gf
                },  {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {
                    

                    const updatedRowData = [...rowsCopy]
                    updatedRowData[index]['NR Specific'] = 0

                    setRowsCopy(updatedRowData)
                    setRows(cloneDeep([...rowsCopy]))
    
                    notify.success('Specific Time Off')
                    
                })
                .catch((e) =>{
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }

                    notify.error('Failed to remove Specific Time')
    
                });

      }



     const deleteCard = (key, index, name) => {

      // event.preventDefault()

      // console.log(index)

      const jsonDeleted = JSON.parse(rowsCopy[key][name])
      jsonDeleted.splice(index, 1)
      const stringifyDeleted = JSON.stringify(jsonDeleted)

      handleDataChange(stringifyDeleted, key, name)


      let gf = 'gf_plus'

      if(gfChecked===true){
          gf = 'deutsche_gf'
      }

      // setUpdateLoading(true)

      axios
                .post(`${BACKEND_URL}/deleteCard`, {
                  rows: rowsCopy[key],
                  gf,
                }
                ,  {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {
                    
                    // setUpdateLoading(false)

                    setHbKomment('')
                    setGbKomment('')
                    setFmaKomment('')
                    setNewReparatura('')

                    setRows(cloneDeep([...rowsCopy]))

                    setProm(!prom)

                    notify.error('Deleted')

                    
                })
                .catch((e) =>{
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }

                    // setUpdateLoading(false)

                    // setRowsCopy(cloneDeep([...rows]))
                    setProm(!prom)

                    notify.error('Failed to update')

                }
                );

      }


      const updateClientStatus = (index) => {

        const id = rows[index].ID


        if(gfChecked===false){
            var gf = 'gf_plus'

            if(rows[index].Kunde===0){
                var kunde = 1
            } else {
                var kunde = 0
            }

        } else {
            var gf = 'deutsche_gf'

            if(rows[index]['GrundNA']==='R0'){
                var kunde = 0
            } else {
                var kunde = 1
            }
        }

        axios
                .post(`${BACKEND_URL}/clientStatus`, {id, gf, kunde},  {
                    headers: {
                    Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                    'Content-Type': 'application/json',
                    }})
                .then((response) => {


                    if(kunde === 0) {
                            notify.success('Removed client')
                    } else {
                            notify.success('Activated client')
                    }

                    
                    // setExpandedRowIndex(null)

                    setProm(!prom)

                    /*const updatedRowData = [...rowsCopy]
                    updatedRowData[index]['NR Specific'] = specificNR
                    setRowsCopy(updatedRowData)
                    setRows(cloneDeep([...rowsCopy]))*/
                    setExpandedRowKeys([]);
                })
                .catch((e) =>{
                    if(e.response.status===403 || e.response.status===401){
                        localStorage.removeItem('usertoken');
                        navigate('/')
                    }

                        notify.error('Failed to update client')
    
                    // setWaitingAxios(false)
                });

    }


    const debouncedHandleChangeText = useCallback(
      debounce((value, name) => {
        // setHbKomment(value)
        if(name==='HB Comment') setHbKomment(value)
        if(name==='Gartenborung Comment') setGbKomment(value)
        if(name==='FMA Comment') setFmaKomment(value)
        if(name==='Reparatura') setNewReparatura(value)
        if(name==='NR') setCommentNR(value)
      }, 300),
      []
    );

    const handleChangeText = (event, name) => {
      debouncedHandleChangeText(event.target.value, name);
    };

    const addNewVom = (values) => {
            
            const name = values.Name.charAt(0).toUpperCase() + values.Name.slice(1).toLowerCase() + " " + values.Surname.charAt(0).toUpperCase() + values.Surname.slice(1).toLowerCase()
            const role = values.Role

            // console.log('new vom: ', name)

            axios
            .post(`${BACKEND_URL}/addVom`, {name, role},  {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {

                setProm(!prom)

                setModalVom(false)

                notify.success('Added new Vom')

            })
            .catch((e) =>{
                if(e.response.status===403 || e.response.status===401){
                    localStorage.removeItem('usertoken');
                    navigate('/')
                }
                notify.error('Failed to add new Vom')

            });
    }

    const deleteVom = (index) => {

            const id = voms[index].ID

            axios
            .post(`${BACKEND_URL}/deleteVom`, {id},  {
                headers: {
                Authorization: `Bearer ${localStorage.getItem('usertoken')}`,
                'Content-Type': 'application/json',
                }})
            .then((response) => {

                setProm(!prom)

                notify.error('Deleted Vom')

            })
            .catch((e) =>{
                if(e.response.status===403 || e.response.status===401){
                    localStorage.removeItem('usertoken');
                    navigate('/')
                }
                notify.error('Failed to delete Vom')

            });

    }

    const data = [
      {
        title: 'Emir Hadzajlic',
      },
      {
        title: 'Emir Hadzajlic',
      },
      {
        title: 'Emir Hadzajlic',
      },
      {
        title: 'Emir Hadzajlic',
      },
      {
        title: 'Emir Hadzajlic',
      },
      {
        title: 'Emir Hadzajlic',
      },
      {
        title: 'Emir Hadzajlic',
      },
    ];

    const tabVomItems = [{
      key: '1',
      label: 'Add Vom',
      children: <Form

      // layout="vertical"
      layout='horizontal'
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      form={form}
      name="form_in_modal"
      initialValues={{ modifier: 'public' }}
      clearOnDestroy
      onFinish={(values) => addNewVom(values)}
      // onFinish={(values) => onCreate(values)}  
    >
  <Form.Item name="Name" label="Name" rules={[{ required: true }, { pattern: /^[^\s]+$/, message: 'Name must not contain spaces' }]} style={{marginTop: 30}}>
    <Input placeholder="Name"/>
  </Form.Item>
  <Form.Item name="Surname" label="Surname" rules={[{ required: true }, { pattern: /^[^\s]+$/, message: 'Surname must not contain spaces' }]}>
    <Input placeholder="Surname"/>
  </Form.Item>
  <Form.Item name="Role" label="Role" rules={[{ required: true }]}>
    <Select
      placeholder="Select Role"
      // onChange={onGenderChange}
      allowClear
      // onChange={(event) => setVomRole(event.target.value)}
    >
      <Option value="Hausbegehung">Hausbegehung</Option>
      <Option value="Tiefbau">Tiefbau</Option>
      <Option value="Faser">Faser</Option>
      <Option value="Montage & Aktievierung">Montage & Aktievierung</Option>
      <Option value="Verrmessung">Verrmessung</Option>
      <Option value="NvT/PoP Spleißen">NvT/PoP Spleißen</Option>
    </Select>
  </Form.Item>
  <Form.Item style={{margin: 'auto'}} wrapperCol={{ span: 22, offset: 15 }}>
      <Button htmlType="reset">reset</Button>
      <Button style={{backgroundColor: '#0AB39C', marginLeft: 10}} type="primary" htmlType="submit">Add</Button>
  </Form.Item>
  </Form>
    }, {
      key: '2',
      label: 'Preview',
      children: <List style={{maxHeight: '50vh', overflowY: 'scroll'}} itemLayout="horizontal" dataSource={voms} renderItem={(item, index) => (
        <List.Item actions={[<Popconfirm  title="Are you sure?" okText="Delete" onConfirm={() => deleteVom(index)}><a key="list-delete"><DeleteOutlined /></a></Popconfirm>]}>
          <List.Item.Meta
            // avatar={<MoreOutlined style={{fontSize: 20, color: '#405189'}}/>}
            title={item.Name}
            description={item.Role}
          />
        </List.Item>
      )} />
    }]
    
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Nav defaultCollapsed={collapsed} collapsed={collapsed} setCollapsed={setCollapsed}/>

      {/* <Layout className="main-layout" style={{marginLeft: collapsed ? 80 : 200, transition: 'margin-left 0.2s'}}> */}
      <Layout className="main-layout" style={{marginLeft: 80}}>
        {/* <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed} logged={logged}/> */}
        <HeaderSection collapsed={collapsed} setCollapsed={setCollapsed}/>

        <Modal
        
        open={modalEing}
        title="Eigentümer Contact"
        onCancel={() => setModalEing(false)}
        destroyOnClose
        footer={null}
       
      >
        <Divider/>

        <p style={{fontWeight: 'bold'}}>Name: <span style={{fontWeight: '100', marginLeft: 50}}>{currrentRecord && currrentRecord['Eingetumename']}</span></p>
        <p style={{fontWeight: 'bold'}}>Tel. 1: <span style={{fontWeight: '100', marginLeft: 50}}>{currrentRecord && currrentRecord['EN tel. 1']}</span></p>
        <p style={{fontWeight: 'bold'}}>Tel. 2: <span style={{fontWeight: '100', marginLeft: 50}}>{currrentRecord && currrentRecord['EN tel. 2']}</span></p>
        <p style={{fontWeight: 'bold'}}>Email: <span style={{fontWeight: '100', marginLeft: 50}}>{currrentRecord && currrentRecord['EN Email']}</span></p>

      </Modal>

        <Modal
        
        open={modalVom}
        title="Workers"
        okText="Create"
        cancelText="Cancel"
        okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
        onCancel={() => setModalVom(false)}
        destroyOnClose
        footer={null}
       
      >
        <Tabs
    defaultActiveKey="1"
    centered
    items={tabVomItems}
  />
      </Modal>

        {contextHolder}

        <div className="page-info">

          <h3>Dashboard {'>'}</h3>

        </div>
        {/* <Divider style={{marginTop: '35px'}} orientation="left" plain>
      Home
      </Divider> */}
        <Content
          style={{
            margin: '24px 16px',
          }}
        >
          <div className="main-buttons">

            <Button size="large" type="primary" icon={<FilterOutlined />} style={{
              // backgroundImage: 'linear-gradient(135deg, #6253E1, #04BEFE)',
              // backgroundColor: '#405189'
              fontSize: 14,
              backgroundColor: '#0AB39C'
              }}>
              Filter
            </Button>


            <div className="right-buttons">

              {(logged.role==='Admin' || logged.role==='Monitoring Team') &&<Button size="large" type="primary" icon={<PlusOutlined />} onClick={() => setModalVom(true)} style={{
                // backgroundImage: 'linear-gradient(135deg, #6253E1, #04BEFE)'
                fontSize: 14,
                backgroundColor: '#0AB39C',
                marginRight: 20
                }}>
                {/* Vom */}
                Worker
              </Button>}

              {logged.role==='Admin' && <Button size="large" type="primary" icon={<DownloadOutlined />} onClick={downloadExcel} style={{
                // backgroundImage: 'linear-gradient(135deg, #6253E1, #04BEFE)'
                fontSize: 14,
                backgroundColor: '#0AB39C'
                }}>
                Download
              </Button>}
            </div>

            

          </div>
          

          <div className="filter-container"
            style={{
              padding: 24,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <div className="main-filters">
              <Input allowClear className="search-input" placeholder="Search" prefix={<SearchOutlined />}  onChange={(event) => 
              {
                // setSearchString(event.target.value)
                handleChange(event)
                // setCurrentPage(1)
              }} />

                {/* <p>Found: {totalClients} Clients</p> */}
                {/* checkedChildren="Deutsche" unCheckedChildren="Plus" */}
              {/* <Switch onChange={(checked) => {
                setGfChecked(checked)
                setCurrentPage(1)
              }}/> */}
              <Select
                className="select-glasfaser"
                defaultValue="gf_plus"
                style={{
                  minWidth: '200px',
                }}
                onChange={(value) => {
                  if(value==='gf_plus') setGfChecked(false)
                  else setGfChecked(true)
                  setCurrentPage(1)
                }}
                options={[
                  {
                    value: 'gf_plus',
                    label: 'Glasfaser Plus',
                  },
                  {
                    value: 'deutsche_gf',
                    label: 'Deutsche Glasfaser',
                  },
                ]}
              />
            </div>

            <div style={{fontSize: '12px', marginTop: '20px'}} className="main-filters">
              <div>
                <Checkbox style={{fontSize: '12px'}} defaultChecked onChange={() => {

                  setCurrentPage(1)
                  setActiveChecked(!activeChecked)

                }}>Active</Checkbox>

                <Checkbox style={{fontSize: '12px'}}  onChange={() => {

                  setCurrentPage(1)
                  setNonActiveChecked(!nonActiveChecked)

                }}>Non-Active</Checkbox>
                
              </div>
              {/* {totalClients} Clients Found */}
            </div>
            
          </div>
          {/* expandRowByClick */}
          <Table title={() => <div className="total-items header-container">
        {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalClients)} of ${totalClients} items`}
      </div>} sticky style={{marginTop: '20px'}} loading={loading} className="custom-table" cellFontSizeSM scrollToFirstRowOnChange scroll={{ x: 'max-content' }} pagination={false} dataSource={rowsCopy} columns={columns} size="small" expandable={{
          expandedRowRender: (record, index) => 

            
            expandedRowRender(record, index, gfChecked, voms, hbKomment, gbKomment, fmaKomment, newReparatura, commentNR, callResult, callReason, setHbKomment, setGbKomment, setFmaKomment, setNewReparatura, setCommentNR, setCallResult, setCallReason, handleDataChange, updateClientData, deleteCard, updateLoading, handleCallClient, removeSpecificCall, updateClientStatus, handleChangeText, logged.role, textAreaRef, form),
            expandedRowKeys: expandedRowKeys,
            onExpand: onExpand,
              // <Tabs
              // style={{margin: '10px', padding: '10px', minHeight: 500}}
              // tabPosition='left'
              // items={tabItems}
              // />
            
          
          }}/>

<div className="pagination-container">
      <div className="total-items">
        {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalClients)} of ${totalClients} items`}
      </div>
      <Pagination
        total={totalClients}
        defaultPageSize={itemsPerPage}
        defaultCurrent={currentPage}
        responsive
        onChange={(page) => {
          setCurrentPage(page)
          window.scrollTo({ top: 300 })
          // tableRef.current.scrollIntoView({ behavior: 'smooth' });
          // setItemsPerPage(pageSize)
        }}
        pageSize={itemsPerPage}
        current={currentPage}
        showSizeChanger={false} // Hide the default page size changer
      />
      <div className="page-size-selector">
        <Select defaultValue={itemsPerPage} value={itemsPerPage} onChange={(size) => {
          setItemsPerPage(size)
          setCurrentPage(1)
          window.scrollTo({ top: 300 })
        }}>
          <Option value={1}>1 rows/page</Option>
          <Option value={5}>5 rows/page</Option>
          <Option value={10}>10 rows/page</Option>
          <Option value={20}>20 rows/page</Option>
          <Option value={50}>50 rows/page</Option>
          <Option value={100}>100 rows/page</Option>
        </Select>
      </div>
      {/* const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        // setItemsPerPage(pageSize);
      };
    
      const handlePageSizeChange = (size) => {
        setItemsPerPage(size);
        setCurrentPage(1);
      }; */}
    </div>
        </Content>
        <FloatButton.BackTop />
        <Footer
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
            fontSize: 13,
          }}
        >
          ©{new Date().getFullYear()} Powered by DHM Projekt
        </Footer>
      </Layout>
    </Layout>
  );


}

export default Home;